.article-el.breaker.no-padding {
	margin-top: 0;
	margin-bottom: 0;
	padding: 0;
}

.universe-cnt {
	--containerHeight: 20vw;
	position: relative;
	height: var(--containerHeight);
	overflow: hidden;
	border-radius: 25px;
	overflow: hidden;
	transform: translateZ(0px);
	/*	transform: translateZ(0);*/
	pointer-events: auto;
	background-color: black;
	width: calc(100% - 40px);

	display: flex;
	justify-content: center;
	align-items: center;
}

@media (max-width: 768px) {
	.universe-cnt {
		--containerHeight: 20vh;
		height: var(--containerHeight);
	}
}

@media (min-width: 2000px) {
	.universe-cnt {
		--containerHeight: 32vh;
		height: var(--containerHeight);
	}
}

.universe-wrapper {
	--itemSize: calc(var(--containerHeight) * 1.45 / 3);
	--responsiveness: 0.2;
	--gapSize: calc(var(--itemSize) * 0.15);
	--x: 0%;
	--y: 0%;
	/*	--translateX: calc(-50%);
	--translateY: calc(-50%);
	transform: translate(var(--translateX), var(--translateY));*/

	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: var(--itemSize);

	pointer-events: none;
	transition: 0.4s ease-out;
	transform: translateZ(0);
}
.first-row,
.second-row,
.third-row,
.fourth-row,
.fith-row {
	display: flex;
	height: 100%;
	gap: var(--gapSize);
	pointer-events: none;
}
.first-row,
.fith-row {
	padding-left: calc(var(--itemSize)+ var(--gapSize));
}

.second-row,
.fourth-row {
	padding-left: calc(var(--itemSize) * 0.5 + var(--gapSize) / 2);
}

.universe-item {
	pointer-events: none;
	--scale: 1;
	width: var(--itemSize);
	height: var(--itemSize);
	border-radius: 50%;
	/*	background-color: #73f;*/
	will-change: transform;
	transform: scale(var(--scale));
	opacity: calc(var(--scale) * var(--scale) * var(--scale) * var(--scale));
	transition: 0.4s ease-out;
	scroll-snap-align: center;
	box-shadow: 0 0 0.2em 0 rgba(0, 0, 0, 0.2);
	background-size: 1105%, 405%;
	background-position: -10% -10%, -10% -10%;
	background-repeat: no-repeat;
	background-image: url("../img/sprite_sheet_universe.png"),
		url("../img/sprite_sheet_universe_bg.png");
}
.universe-item:hover {
	background-color: #2b1a4d;
}
/*@media screen and (max-width: 1000px) {
	body,
	html {
		position: relative;
		overflow: scroll;
		scroll-behavior: smooth;
		-webkit-overflow-scrolling: touch;
	}
	.universe-cnt {
	}

	.uniserve-wrapper {
		--itemSize: calc(var(--containerHeight) / 3);
		scroll-snap-align: center;
		overflow: scroll-x;
		position: relative;
		-webkit-overflow-scrolling: touch;
		scroll-behavior: smooth;
	}
	.first-row,
	.second-row,
	.third-row,
	.fourth-row,
	.fith-row {
		position: static;
	}
}
*/

@supports (not (translate: none)) {
	.universe-wrapper {
		display: none;
	}
	.universe-cnt {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url("../img/universe-fallback.jpg");
	}
}
