.slider-wrapper {
	position: fixed;
	backface-visibility: hidden;
	color: #000;

	visibility: inherit;
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	vertical-align: baseline;
	display: flex;
	top: 0;
	left: 0;
	width: 100%;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	height: 100%;
	-webkit-box-align: center;
	align-items: center;
	overflow: hidden;
}

.slider {
	backface-visibility: hidden;
	color: #000;
	-webkit-font-smoothing: antialiased;
	visibility: inherit;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	vertical-align: baseline;
	list-style: none;
	z-index: 1;
	white-space: nowrap;
	font-size: 0;
	/*	touch-action: pan-y;*/
	height: 100%;

	width: 100%;

	transform: translate3d(-3.008px, 0px, 0px);
}

.poster {
	/*	filter: grayscale(100%);*/
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
	background-repeat: no-repeat;
	background-size: cover;
	backface-visibility: hidden;
	pointer-events: none;
	opacity: 1;

	background-position: center;
}

.slide {
	backface-visibility: hidden;
	-webkit-font-smoothing: antialiased;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

	list-style: none;
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	position: absolute;
	z-index: 1;
	display: inline-block;
	vertical-align: top;
	color: #fff;
	white-space: normal;
	width: 36.54843%;

	/*	background-color: black;*/
	overflow: overflow-y;

	/*	transition: transform 1s cubic-bezier(0.16, 1.08, 0.38, 0.98),
		-webkit-transform 1s cubic-bezier(0.16, 1.08, 0.38, 0.98);*/
}

/*@media (orientation: portrait) {
	.slide {
		pointer-events: none;
	}
}*/

.slide.portrait {
	pointer-events: none;
}

video.media {
	text-align: center;

	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	list-style: none;
	color: #fff;
	white-space: normal;
	visibility: inherit;
	-webkit-user-drag: none;
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	vertical-align: baseline;

	position: absolute;
	z-index: 0;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

video.media.portrait {
	height: calc(101vw * 1.6875);
	width: auto;
}

@media (min-aspect-ratio: 1280/2160) {
	video.media.portrait {
		height: 100%;
		width: auto;
	}

	video.media.split.portrait {
		height: calc(101vw * 1.6875);
		width: auto;
	}
}

video.media {
	height: auto;
	width: 100%;
}

video.media.split {
	height: auto;
	width: 100%;
}

@media (max-aspect-ratio: 16/9) {
	video.media {
		height: 100%;
		width: auto;
	}

	video.media.split {
		height: auto;
		width: calc(var(--vh, 1vh) * 100 * 16 / 9);
	}
}

/*@media (max-aspect-ratio: 2160/1280) {
	video.media.portrait {
		height: calc(101vw * 1.6875);
		width: auto;
	}
}*/

.videoContainer {
	backface-visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	transform: translateZ(0);
}

.fixed {
	top: 0;
	height: 100%;
	left: 0;
	width: 100%;
}
.slide::before {
	content: "";
	display: block;
	width: 100%;
}
.slide::after {
	content: "";
	display: block;
	width: 100%;
}
.content {
	width: 100%;
	position: absolute;
	top: 100%;
	padding-left: 20px;
	font-weight: 700;
}

h2,
h3 {
	padding: 20px;
	display: inline-block;
	overflow: visible;
	transform: translate(0px, 0px);
	font-family: F37bobby, sans-serif;
	color: #fffcfa;
	font-size: 34px;
	line-height: 34px;
	font-weight: 700;
}

.main-el-topic,
.index-topic {
	font-family: F37jan, sans-serif;
	font-weight: 700;
}

.carousel--progress {
	position: fixed;
	z-index: 2;
	pointer-events: none;
	bottom: 60px;

	width: 20vw;
	left: calc(50% - 10vw);
	height: 5px;
	background: rgba(255, 255, 255, 0.2);
	border-radius: 20px;
	overflow: hidden;
}
.carousel--progress-bar {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.8);
	transform: scaleX(0);
	transform-origin: 0% 0%;
	border-radius: 20px;
	overflow: hidden;
}

.sol-title-wrapper {
	overflow: hidden;
	z-index: 100000;
	pointer-events: None;
}

.black-overlay {
	overflow: hidden;
	pointer-events: none;
	position: absolute;
	background-color: #000;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1;
}

.sol-fs-progressbar {
	transform: translateZ(0); /*fix rounded borders overflow iOS Mobile Safari*/
}

.slider,
.sol-fs-txt {
	will-change: transform, opacity;
}
.sol-fs-txt-v2 {
	/*	-webkit-overflow-scrolling: auto;*/

	overflow-y: scroll;
	touch-action: pan-y;
}

.sol-fs-txt-v2::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sol-fs-txt-v2 {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	transform: translateZ(0);
	will-change: scroll-position;

	/*	scroll-behavior: smooth;*/
}

/*.fade {
	-webkit-mask-image: linear-gradient(
		180deg,
		transparent 0px,
		#fff 80px,
		#fff calc(100% - 160px),
		transparent calc(100% - 110px)
	);
}*/

.black-overlay-g {
	background: linear-gradient(180deg, transparent, #000);
	overflow: hidden;
	pointer-events: none;
	position: absolute;
	opacity: 0;
	width: 100%;

	height: calc(var(--bottomOffset) + 160px);
	bottom: 0;
	left: 0;
	z-index: 1;
	transform: translateZ(0);
}

.black-overlay-g.bottom-bg {
	height: calc(var(--bottomOffset) + 320px);
	opacity: 0.38;
	z-index: 0;
}
.black-overlay-g.top {
	pointer-events: none;
	background: linear-gradient(0deg, transparent, #000);
	height: 100px;
	top: 0;
	background-color: "red";
}

/*.sol-fs-scrollmore {
	opacity: 0.1 !important;
}*/

.complications-wrapper {
	pointer-events: none;
	padding-top: calc(var(--vh, 1vh) * 14);
	padding-bottom: calc(var(--vh, 1vh) * 14);
}

/*.complication-el {
	scale: 0.8;
}*/

.article-el.breaker {
	margin-left: calc(-1 * (100vw - 980px) / 2);
}

@media screen and (max-width: 991px) {
	.article-el.breaker {
		margin-left: calc(-1 * (100vw - 692px) / 2);
		/*		background: green;*/
	}
}

@media screen and (max-width: 768px) {
	.article-el.breaker {
		margin-left: 0px;
		/*		background: blue;*/
	}
}
