.video-content {
	height: calc(100vw * 9 / 16);
	width: 100%;
	max-height: 720px;
}

@media (min-aspect-ratio: 16/9) {
	.video-content {
		max-width: 1280px;
		width: calc(100vh * 16 / 9);
		height: 100%;
	}
}

.modalVid {
	-webkit-transition: opacity 200ms ease, -webkit-transform 200ms ease;
	transition: opacity 200ms ease, -webkit-transform 200ms ease;
}
