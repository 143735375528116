.section-wrapper {
	pointer-events: none;
}
.section-div {
	pointer-events: var(--sectionDivPointerEvents);
}
.article-el white,
.section-div white {
	color: #fffcfaff;
}
.article-el pink,
.section-div pink {
	color: #f10388ff;
}
