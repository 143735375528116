.article-video-cnt {
	position: relative;
}
.article-video {
	width: 100%;
	height: auto;
}
.article-video-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;

	background-image: url("../img/home-vid-overlay-influencer.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 60% 50%;
}

.article-video.round {
	border-radius: 25px;
	transform: translateZ(0px);
}

.article-el.padding {
	padding-left: 40px;
	padding-right: 40px;
}
.grad-txt {
	background: linear-gradient(45deg, #f10388, #935dea);
	background-size: 100%;
	padding-bottom: 10px;
	margin-bottom: -10px;
	/*	background: white;*/
	/*	text-align: center;*/
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	/*	animation: animated_text 10s ease-in-out infinite;
	-moz-animation: animated_text 10s ease-in-out infinite;
	-webkit-animation: animated_text 10s ease-in-out infinite;*/
}
