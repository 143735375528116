* {
	cursor: url("../img/cursor.png") 9 9, pointer; /* Legacy */
	cursor: url("../img/cursor.svg") 9 9, pointer; /* FF */
	cursor: -webkit-image-set(
				url("../img/cursor.png") 1x,
				url("../img/cursor@2x.png") 2x
			)
			9 9,
		pointer; /* Webkit */
}
.images-loaded-container {
	position: absolute;
	overflow: hidden;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.images-loaded-container img {
	max-width: var(--img-maxwidth);
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	will-change: transform;
	filter: var(--filter-img);
	pointer-events: none;
	touch-action: none;
}

.article-h2 {
	filter: contrast(1);
}

.main-el,
.index {
	background: rgb(241, 3, 136);
	transform: translateZ(0);

	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f10388",endColorstr="#ffffff",GradientType=1);

	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	background-size: 200%;
}

.progress-wrap svg path {
	fill: none;
	pointer-events: none;
	touch-action: none;
}
.progress-wrap {
	position: fixed;
	display: block;

	height: 40px;
	width: 40px;

	display: block;
	border-radius: 50px;
	box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.1);
	z-index: 10000;
	transform: translateX(0);
	pointer-events: none;
	touch-action: none;
}
.progress-wrap svg.progress-circle path {
	stroke: #fff;
	stroke-width: 8;
	box-sizing: border-box;

	pointer-events: none;
	touch-action: none;
}

.body {
	/*	overflow: hidden;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 1;*/

	-webkit-user-select: none;
	-moz-user-select: -moz-none;
	-ms-user-select: none;
	user-select: none;
	touch-action: none;
}

.preloader,
.s-logo,
.s-logo-cover {
	transform: translateX(0);
}

.circle {
	position: fixed;
	top: 0;
	left: 0;
	pointer-events: none;
	touch-action: none;

	position: fixed;
	display: block;
	left: 0;
	top: 0;
	transform: translate(-50%, -50%);
	width: 25px;
	height: 25px;
	/*border: 2px solid #fff;
	border-radius: 50%;*/
	pointer-events: none;
	touch-action: none;
	opacity: 1;
	background-color: rgba(0, 0, 0, 0);
	z-index: 400000000;
}

.landing-text-el-wrap,
.landing-text-el {
	background-color: rgba(0, 0, 0, 0);
	cursor: none;

	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

a,
.link,
.lading-btn-container,
.header-logo,
.lamalogo,
button,
a:hover,
.btn-parallax-container,
.header-back-btn,
.say-hello,
.say-hello-under,
.header-burger-btn-insta,
.header-center-btn-cnt,
.slide {
	background-color: rgba(0, 0, 0, 0);

	cursor: url("../img/cur_mag.png") 9 9, pointer !important; /* Legacy */
	cursor: url("../img/cur_mag.svg") 9 9, pointer !important; /* FF */
	cursor: -webkit-image-set(
				url("../img/cur_mag.png") 1x,
				url("../img/cur_mag@2x.png") 2x
			)
			9 9,
		pointer !important; /* Webkit */

	-webkit-touch-callout: none !important; /* iOS Safari */
	-webkit-user-select: none !important; /* Safari */
	-khtml-user-select: none !important; /* Konqueror HTML */
	-moz-user-select: none !important; /* Old versions of Firefox */
	-ms-user-select: none !important; /* Internet Explorer/Edge */
	user-select: none !important; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.landing-text-el,
.landing-text-el.nohover {
}

* {
	-webkit-font-smoothing: antialiased;
}

.landing-text-el-wrap,
.lading-btn-container,
.btn-parallax-container {
	perspective: 1000px;
	/*fix safari lading-btn */
	transform: translateX(0);
}

.scope-lr,
.scope-td {
	transform: translateX(0);
}

.main-el,
.index,
.landing-text-el,
.video-react,
.landing-btn {
	pointer-events: none;
	touch-action: none;
	background-color: rgba(0, 0, 0, 0);
}
body {
	background-color: #000 !important;
}

/* unvisited link */
a:link {
	text-decoration: none;
	color: white; /* visited link */
}
a:visited {
	color: white;
}
/* mouse over link */
a:hover {
	color: white;
}

/* selected link */
a:active {
	color: white;
}

/*CONTACT*/

.outer {
	align-items: center;
	border: 1px solid #ccc;
	display: flex;
	height: 20vw;
	justify-content: center;
	max-width: 100%;
	overflow: auto;
	padding: 50px;
}

svg {
	height: 100%;
}

circle {
}

path {
	fill: rgba(0, 0, 0, 0);
}

text {
	fill: #fff;
	font-size: 4rem;
	font-family: sans-serif;
}

.disabled-link {
	pointer-events: none;
	touch-action: none;
}

a.disabled-link {
	text-decoration: none !important;
	pointer-events: none;
	touch-action: none;
}
.open-for-busi a {
	color: #f10388 !important;
	text-transform: uppercase;
	text-decoration: none;
}

.contact-box-text a {
	color: #fff;

	text-decoration: none;
}

/* COOKIE CONTAINER*/
.page-content.cookie {
	overflow-y: scroll;
}

.lading-btn-container a {
	text-decoration: none;
}

.lading-btn-containter a:hover,
.lading-btn-containter a:active,
.lading-btn-containter a:focus {
	outline: none;
	border: 0;
}

.lading-btn-containter {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

a.cookie {
	text-decoration: none;
	color: rgba(255, 255, 255, 0.5);
}

.emo-c,
.emo-g,
.emo-r,
.emo-e,
.emo-m,
.emo-j,
.contact-box-bg {
	transform: translateZ(0);
}

.grid {
	/*	z-index: 1000000;*/
	pointer-events: none;
	touch-action: none;
}

.cookie-wrapper {
	pointer-events: none;
	z-index: 12;
}
.cookie-background {
	pointer-events: auto;
	transform: translateX(0);
}
.btn-parallax,
.btn-parallax-text {
	pointer-events: none;
}
.btn-parallax-container {
	perspective: 1000px;
	/*fix safari lading-btn */
	transform: translateX(0);
}
body {
	overflow: hidden;
}
.slider-wrapper {
	overflow: auto;
	/*	-webkit-overflow-scrolling: touch;*/
}

.sol-title-wrapper {
	overflow: hidden;
	pointer-events: none;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}

.prevent-select {
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}

.slider-wrapper::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.slider-wrapper {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.solution-fs-wrapper {
	pointer-events: none;
	/*	overflow: hidden;*/
	z-index: 2;
	vertical-align: top;
	-webkit-overflow-scrolling: touch;
}

.sol-fs-btn-cnt {
	pointer-events: auto;
}

.sol-main-el {
	font-weight: bold;
}

.sol-fs-txt {
	-webkit-overflow-scrolling: touch;
	pointer-events: auto !important;
}

/*.fadeInvert {
	-webkit-mask-image: linear-gradient(180deg, transparent 0%, #000 10% 100%);
}*/

@media (min-width: 768px) {
	.sol-fs-btn-cnt.landscape {
		/*		margin-bottom: calc((100vh - (100vw * 0.3654843) * (9 / 16) / 3));*/
		margin-bottom: calc(
			((100vh - (100vw * 0.3654843) * (9 / 16)) / 2) / 2 - 110px
		);
	}
}

@media (orientation: landscape) {
	.sol-fs-headertxt-wrapper {
		/*		margin-bottom: calc((100vh - (100vw * 0.3654843) * (9 / 16) / 3));*/
		margin-bottom: calc(
			((100vh - (100vw * 0.3654843) * (9 / 16)) / 2) / 2 - 160px
		);
		opacity: 0;
	}
}

svg.progress-circle-btn path {
	stroke: #fff;

	pointer-events: none;
	touch-action: none;
}

html {
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
	touch-action: pan-y; /*disable zoom*/
}

.complication-record-time {
	font-variant-numeric: tabular-nums;
}

/*.back-txt {
	text-shadow: 0px 0px 35px black;
}*/

.top-filler,
.sol-fs-txt-v2 {
	cursor: url("../img/cursor-cross.png") 9 9, pointer !important; /* Legacy */
	cursor: url("../img/cursor-cross.svg") 9 9, pointer !important; /* FF */
	cursor: -webkit-image-set(
				url("../img/cursor-cross.png") 1x,
				url("../img/cursor-cross@2x.png") 2x
			)
			9 9,
		pointer !important; /* Webkit */
}

/*.txt-grad div {
	background-size: 200% auto;
	background: -webkit-linear-gradient(
		0deg,
		#f10388,
		#ed64a6,
		#f10388,
		#f10388
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: shine 1s linear infinite;
}*/

.txt-grad div {
	height: calc(var(--headerLineHeight) * 2);
	top: 0px;
	margin-bottom: calc(var(--headerLineHeight) * -1);
}

.ar-item-subheader.estimate {
	height: calc(var(--headerLineHeight) * 2);
	top: 0px;
	margin-bottom: calc(var(--headerLineHeight) * -1);
}

.txt-grad div,
.ar-item-subheader.estimate {
	background: linear-gradient(45deg, #f10388, #f10388, #935dea, #935dea);
	background-size: 300%;
	background: white;
	text-align: center;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: animated_text 10s ease-in-out infinite;
	-moz-animation: animated_text 10s ease-in-out infinite;
	-webkit-animation: animated_text 10s ease-in-out infinite;
}
/*.txt-grad.work div {
	background: linear-gradient(-90deg, #f10388, #f10388, #935dea, #935dea);
	background-size: 300%;

	text-align: center;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: animated_text 10s ease-in-out infinite;
	-moz-animation: animated_text 10s ease-in-out infinite;
	-webkit-animation: animated_text 10s ease-in-out infinite;
}*/
.text-white-header-index,
.sol-index,
.slider-index {
	vertical-align: top;
	display: inline-block;
}
@keyframes animated_text {
	0% {
		background-position: 0px 50%;
	}
	50% {
		background-position: 50% 50%;
	}
	100% {
		background-position: 0px 50%;
	}
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroll {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.message-txt {
	word-wrap: break-word;
}
