.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

body {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 20px;
}

.virtual-influencer {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.background-video {
  position: absolute;
  z-index: -1;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.text-block {
  font-family: "Cormorant Garamond";
  color: #fff;
  font-size: 90px;
  line-height: 90px;
  font-weight: 400;
  text-transform: none;
}

.page-content {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-top: 5%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
}

.page-content.white {
  overflow: hidden;
  padding-top: 0px;
}

.page-content.center {
  padding-top: 0%;
  padding-right: 0px;
  padding-left: 0px;
}

.page-content.cookie {
  overflow: auto;
  padding-bottom: 5%;
  justify-content: flex-start;
}

.page-content.scroll {
  display: block;
  overflow: auto;
  height: auto;
  padding-top: 0%;
  justify-content: flex-start;
}

.body {
  background-color: #000;
  font-family: F37jan, sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.text-span {
  cursor: pointer;
}

.text-span:hover {
  color: #fa0f8e;
}

.header {
  position: fixed;
  z-index: 10;
  display: flex;
  width: 100%;
  height: 100px;
  margin-top: 0px;
  padding-top: 15px;
  padding-right: 40px;
  padding-left: 40px;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.header.hidden {
  display: none;
}

.header.hide {
  display: none;
}

.header-logo {
  position: absolute;
  width: 100px;
  height: 29px;
  margin-top: -1px;
  background-image: url("../img/logo.png");
  background-position: 50% 50%;
  background-size: 100px;
  background-repeat: no-repeat;
}

.header-logo.lama {
  width: 44px;
  height: 44px;
  background-image: url("../img/s_logo_small.png");
  background-position: 0px 0px;
  background-size: contain;
}

.text-block-2 {
  padding-top: 16px;
  padding-right: 209px;
  padding-bottom: 24px;
  font-family: "Cormorant Garamond";
  color: #fff;
  font-size: 90px;
  letter-spacing: 0px;
  text-transform: none;
}

.text-block-2.asd {
  padding-top: 0px;
  padding-right: 75px;
  padding-left: 0px;
  line-height: 72px;
  text-align: center;
}

.header-button {
  display: none;
  padding: 17px 0px 30px;
  border-width: 4px;
  border-color: #f10388;
  border-radius: 40px;
  transition: color 200ms ease;
  color: hsla(0, 0%, 100%, 0.4);
  letter-spacing: 1px;
}

.textintent {
  position: relative;
  top: -44px;
  font-size: 25px;
}

.pinky {
  color: #f10388;
}

.text-span-2 {
  font-size: 80px;
}

.footer {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  width: 100%;
  height: 60px;
  padding-left: 40px;
  justify-content: space-between;
  color: hsla(0, 0%, 100%, 0.4);
}

.footer.hide {
  display: none;
}

.footer-left {
  color: #fff;
}

.footer-right {
  display: inline-block;
  transition: color 200ms ease;
}

.footer-right:hover {
  color: #fff;
}

.div-block {
  display: none;
}

.page-content-container {
  display: flex;
  justify-content: center;
}

.page-content-container.right {
  text-align: center;
}

.page-content-container.awardribbon {
  width: 75%;
  background-image: url("../img/award-ribbon.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.landing-text-el {
  display: inline-block;
  overflow: visible;
  height: 96px;
  padding: 5px 10px;
  transform: translate(0px, 0px);
  font-family: F37bobby, sans-serif;
  color: #fff;
  font-size: 77px;
  line-height: 86px;
  font-weight: 300;
}

.landing-text-el.spacer {
  padding-right: 5px;
  padding-left: 10px;
  color: #f10388;
}

.landing-text-el.tmrws {
  transform: translate(0px, 0px);
}

.landing-text-el.nohover {
  font-family: F37bobby, sans-serif;
  font-weight: 300;
}

.landing-text-el.nohover.left {
  padding-left: 0px;
}

.landing-text-el.nohover.left.jan {
  font-family: F37jantest;
  color: #f10388;
  font-size: 36px;
}

.landing-text-el.mouseover {
  transform: none;
  color: #f10388;
}

.landing-text-el.overlay {
  position: absolute;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  width: 150px;
  background-color: #000;
  color: #f10388;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.landing-text-el-copy {
  display: inline-block;
  height: 110px;
  margin-bottom: 0px;
  padding: 40px 20px 20px;
  transform: translate(0px, 0px);
  font-family: "Cormorant Garamond";
  color: #fff;
  font-size: 90px;
}

.landing-text-el-copy.spacer {
  padding-right: 0px;
  padding-left: 0px;
  color: #f10388;
}

.landing-text-el-copy.tmrws {
  transform: translate(0px, 0px);
}

.main-el {
  display: inline-block;
  height: 90px;
}

.main-el.overlay {
  position: absolute;
}

.main-el.higher {
  height: 95px;
}

.index {
  position: relative;
  top: -34px;
  display: inline-block;
  font-size: 20px;
}

.landing-text-el-wrap {
  display: inline-block;
}

.scope-lr {
  position: absolute;
  z-index: 1;
  width: 50%;
  height: 100%;
  background-color: #000;
}

.scope-td {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 50%;
  background-color: #000;
}

.landing-btn {
  display: flex;
  height: 50px;
  padding-top: 12px;
  padding-right: 50px;
  padding-left: 32px;
  justify-content: center;
  border-style: solid;
  border-width: 3px;
  border-color: #f10388;
  border-radius: 150px;
  color: #fff;
  letter-spacing: 1px;
}

.landing-btn.hover {
  background-color: #f10388;
}

.text-block-3 {
  padding-top: 15px;
  padding-left: 44px;
  font-family: Montserrat, sans-serif;
  color: #fff;
}

.text-span-3 {
  display: inline-block;
  padding-left: 10px;
  font-size: 12px;
  line-height: 0px;
}

.bold-text {
  display: inline-block;
}

.lading-btn-container {
  display: inline-block;
  margin-top: 44px;
  margin-bottom: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.div-block-2 {
  display: none;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.landing-page {
  display: block;
}

.landing-page.scroll {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing-page.scroll.bg {
  background-image: url("../img/img_01_compressed_resized.png");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.div-block-4 {
  display: -ms-grid;
  display: grid;
  width: 80%;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-areas: "Area";
  -ms-grid-columns: 1fr 16px 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto 16px auto;
  grid-template-rows: auto auto;
}

.text-block-4 {
  font-family: Montserrat, sans-serif;
  color: #f10388;
  font-size: 36px;
  line-height: 36px;
}

.text-block-5 {
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
}

.text-block-6 {
  font-family: "Cormorant Garamond";
}

.text-block-7 {
  font-family: "Cormorant Garamond";
}

.text-block-8 {
  font-family: "Cormorant Garamond";
}

.text-block-9 {
  display: inline-block;
  float: left;
}

.landing-btn-fixed {
  margin-right: auto;
  margin-left: auto;
  padding-right: 6px;
}

.lading-btn-float-container {
  width: 75px;
  height: 20px;
  float: left;
}

.landing-btn-float-el {
  position: absolute;
}

.landing-btn-arrow {
  width: 50px;
  height: 50px;
  margin-top: -13px;
  margin-left: 27px;
  float: right;
  background-image: url("../img/arrow.svg");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.landing-btn-copy {
  display: inline-block;
  padding: 15px 84px 15px 35px;
  float: left;
  border-style: solid;
  border-width: 3px;
  border-color: #f10388;
  border-radius: 50px;
  background-image: url("../img/arrow_right.png");
  background-position: 95% 50%;
  background-size: 40px;
  background-repeat: no-repeat;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-weight: 500;
  letter-spacing: 1px;
}

.landing-btn-copy.hover {
  background-color: #f10388;
}

.cookie {
  transition: color 200ms ease;
  color: hsla(0, 0%, 100%, 0.4);
}

.cookie:hover {
  color: #fff;
}

.header-btn-text-el {
  display: inline-block;
  padding-right: 0px;
  transition: color 200ms ease;
}

.header-btn-text-el:hover {
  color: #fff;
}

.side-contact {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 2;
  display: none;
  width: 20px;
  height: 100%;
  padding-right: 57px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.side-contact-container {
  display: flex;
  padding-top: 7px;
  padding-left: 17px;
  opacity: 0.4;
  transform: rotate(-90deg);
  color: #fff;
  letter-spacing: 1px;
}

.side-contact-container:hover {
  opacity: 1;
}

.hey-ico {
  position: relative;
  left: -15px;
  top: -2px;
  right: 0px;
  display: inline;
  width: 25px;
  height: 25px;
  float: left;
  background-image: url("../img/hey2x.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(90deg);
}

.hey-txt {
  display: inline;
  float: left;
  font-weight: 600;
}

.preloader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 10;
  overflow: hidden;
  width: 86px;
  height: 86px;
  margin-top: -43px;
  margin-left: -43px;
  border-radius: 25px;
}

.s-logo {
  width: 100%;
  height: 100%;
  border-radius: 0px;
  background-color: #181919;
  background-image: url("../img/hum_v2.png");
  background-position: 75% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.s-logo-cover {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: #181919;
}

.header-btn-text-el-spacer {
  display: inline-block;
  padding-right: 15px;
  padding-left: 15px;
  color: #f10388;
  font-weight: 600;
}

.div-block-5 {
  position: absolute;
  width: 100%;
  height: 100%;
}

.preloaderwrapper {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: block;
  width: 100%;
  height: 100%;
}

.preloaderwrapper.hide {
  display: none;
}

.landing-page-container {
  position: relative;
  display: block;
  width: 100%;
  height: 90vh;
}

.section {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.article-h1 {
  display: inline-block;
  overflow: visible;
  height: 36px;
  margin-bottom: 0px;
  transform: translate(0px, 0px);
  font-family: F37jantest;
  color: #f10388;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
}

.article-h1.spacer {
  padding-right: 0px;
  padding-left: 0px;
  color: #f10388;
}

.article-h1.tmrws {
  transform: translate(0px, 0px);
}

.article-h1.nohover {
  padding-right: 0px;
  font-family: F37bobbytest;
  font-weight: 300;
}

.article-h1.nohover.left {
  padding-left: 44px;
}

.article-h1.mouseover {
  transform: none;
  color: #f10388;
}

.article-h1.overlay {
  position: absolute;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  width: 150px;
  background-color: #000;
  color: #f10388;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.article-h2 {
  display: inline-block;
  overflow: visible;
  height: 120px;
  margin-bottom: 0px;
  padding-top: 12px;
  padding-right: 20px;
  transform: translate(0px, 0px);
  font-family: F37bobbytest;
  color: #fff;
  font-size: 80px;
  line-height: 90px;
  font-weight: 300;
  text-align: center;
}

.article-h2.spacer {
  padding-right: 0px;
  padding-left: 0px;
  color: #f10388;
}

.article-h2.tmrws {
  transform: translate(0px, 0px);
}

.article-h2.nohover {
  padding-right: 0px;
  font-family: F37bobbytest;
  font-weight: 300;
}

.article-h2.nohover.left {
  padding-left: 44px;
}

.article-h2.mouseover {
  transform: none;
  color: #f10388;
}

.article-h2.overlay {
  position: absolute;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  width: 150px;
  background-color: #000;
  color: #f10388;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.article-h2.left {
  text-align: left;
}

.article-content-container {
  position: absolute;
  display: flex;
  overflow: auto;
  width: 100%;
  height: 100%;
  padding-top: 5%;
  padding-right: 250px;
  padding-left: 250px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 auto;
  text-align: center;
}

.article-a1 {
  display: inline-block;
  overflow: visible;
  transform: translate(0px, 0px);
  font-family: F37jantest;
  color: #fff;
  font-size: 16px;
  line-height: 30px;
  font-weight: 700;
  text-align: center;
}

.article-a1.spacer {
  padding-right: 0px;
  padding-left: 0px;
  color: #f10388;
}

.article-a1.tmrws {
  transform: translate(0px, 0px);
}

.article-a1.nohover {
  padding-right: 0px;
  font-family: F37bobbytest;
  font-weight: 300;
}

.article-a1.nohover.left {
  padding-left: 44px;
}

.article-a1.mouseover {
  transform: none;
  color: #f10388;
}

.article-a1.overlay {
  position: absolute;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  width: 150px;
  background-color: #000;
  color: #f10388;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.article-a1.left {
  float: left;
  clear: left;
  text-align: left;
}

.article-a1.head {
  padding-top: 38px;
}

.columns {
  width: 100%;
}

.article-h3 {
  display: inline-block;
  overflow: visible;
  padding-bottom: 3px;
  float: left;
  clear: left;
  transform: translate(0px, 0px);
  font-family: F37jantest;
  color: #f10388;
  font-size: 36px;
  line-height: 30px;
  font-weight: 700;
  text-align: left;
}

.article-h3.spacer {
  padding-right: 0px;
  padding-left: 0px;
  color: #f10388;
}

.article-h3.tmrws {
  transform: translate(0px, 0px);
}

.article-h3.nohover {
  padding-right: 0px;
  font-family: F37bobbytest;
  font-weight: 300;
}

.article-h3.nohover.left {
  padding-left: 44px;
}

.article-h3.mouseover {
  transform: none;
  color: #f10388;
}

.article-h3.overlay {
  position: absolute;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  width: 150px;
  background-color: #000;
  color: #f10388;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.column {
  text-align: left;
}

.article-sec {
  display: flex;
  width: 100%;
  padding-top: 150px;
  flex-direction: row;
  justify-content: flex-end;
  flex: 0 0 auto;
}

.article-sec.right {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
}

.article-sec.left {
  justify-content: flex-start;
}

.article-sec.middle {
  flex-direction: column;
}

.article-cont {
  display: flex;
  max-width: 600px;
  flex-direction: column;
}

.background-img-1 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  width: 100%;
  height: 85vh;
  background-image: url("../img/article_01.png");
  background-position: 100% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.background-img-2 {
  position: absolute;
  width: 50vh;
  height: 50vh;
  margin-top: 193px;
  clear: both;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  background-image: url("../img/article_02.png");
  background-position: 0% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.header-burger-btn {
  display: none;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  flex: 0 auto;
  background-image: url("../img/grid.svg");
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
  -o-object-fit: fill;
  object-fit: fill;
}

.grid {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.grid.hide {
  display: none;
}

.grid-line {
  position: absolute;
  width: 20%;
  height: 100%;
  border-left: 1px solid hsla(0, 0%, 100%, 0.2);
}

.grid-line._1 {
  margin-left: 20%;
}

.grid-line._2 {
  margin-left: 40%;
}

.grid-line._3 {
  margin-left: 60%;
}

.grid-line._4 {
  width: 15%;
  margin-left: 80%;
}

.grid-line._0 {
  width: 15%;
  margin-left: 0%;
  border-right: 0px solid #fff;
  border-left-width: 0px;
}

.emo-c {
  position: fixed;
  width: 100px;
  height: 100px;
  background-image: url("../img/emo_c.png");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.emo-r {
  position: fixed;
  width: 100px;
  height: 100px;
  background-image: url("../img/emo_r.png");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.emo-m {
  position: fixed;
  width: 110px;
  height: 100px;
  background-image: url("../img/emo_m.png");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.emo-g {
  position: fixed;
  width: 105px;
  height: 105px;
  background-image: url("../img/emo_g.png");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.emo-e {
  position: fixed;
  width: 80px;
  height: 80px;
  background-image: url("../img/emo_e.png");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.emo-j {
  position: fixed;
  width: 100px;
  height: 100px;
  background-image: url("../img/emo_j.png");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.contact-box {
  position: relative;
  top: 20px;
  display: flex;
  width: 350px;
  height: 350px;
  max-height: 350px;
  max-width: 350px;
  padding: 40px;
  justify-content: center;
  align-items: center;
}

.contact-box-text {
  position: relative;
  z-index: 1;
  flex: 0 0 auto;
  color: #fff;
  line-height: 25px;
  text-align: center;
}

.open-for-busi {
  font-family: F37bobbytest;
  color: #f10388;
  text-transform: uppercase;
}

.contact-box-bg {
  position: absolute;
  width: 80%;
  height: 80%;
  background-image: url("../img/ofb_circle_v022x.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.contact-box-bg.black {
  display: none;
  border-radius: 350px;
  background-color: #000;
  background-image: none;
  transform: scale(1.2);
}

.contact-text {
  position: relative;
  display: inline-block;
  overflow: visible;
  margin-top: 35px;
  padding-right: 0px;
  padding-left: 0px;
  transform: translate(0px, 0px);
  font-family: F37bobby, sans-serif;
  color: #f10388;
  font-size: 77px;
  line-height: 80px;
  font-weight: 300;
}

.contact-text.spacer {
  padding-right: 21px;
  padding-left: 0px;
  color: #f10388;
}

.contact-text.tmrws {
  transform: translate(0px, 0px);
}

.contact-text.nohover {
  font-family: F37bobbytest;
  font-weight: 300;
}

.contact-text.nohover.left {
  padding-left: 0px;
}

.contact-text.nohover.left.jan {
  font-family: F37jantest;
  color: #f10388;
  font-size: 36px;
}

.contact-text.mouseover {
  transform: none;
  color: #f10388;
}

.contact-text.overlay {
  position: absolute;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  width: 150px;
  background-color: #000;
  color: #f10388;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.contact-text.desc {
  width: 100%;
  max-width: 500px;
  margin-top: 11px;
  padding-right: 5px;
  padding-left: 3px;
  font-family: F37jan, sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 25px;
  font-weight: 700;
}

.contact-box-logo {
  z-index: 1;
  width: 100px;
  height: 44px;
  flex: 0 0 auto;
  background-image: url("../img/logo.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.contact-center-content {
  position: absolute;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.circle02 {
  width: 20px;
  height: 20px;
  margin-top: 0px;
  margin-left: -39px;
  border-radius: 60px;
  background-color: #f10388;
}

.lamalogo {
  position: absolute;
  width: 40px;
  height: 40px;
  margin-top: -7px;
  background-image: url("../img/hum_v2.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.headerlogocontainer {
  position: relative;
  width: 100px;
  height: 29px;
  flex: 1 0;
}

.awardribbon {
  width: 40vw;
  height: 60px;
  margin-top: 6%;
  background-image: url("../img/award-ribbon.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.award-container {
  display: flex;
  width: 100%;
  height: 40px;
  padding-top: 78px;
  justify-content: center;
  align-items: center;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.aw_adc {
  width: 40px;
  height: 40px;
  background-image: url("../img/adc.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.aw_adc-copy {
  width: 40px;
  height: 40px;
  background-image: url("../img/adc.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.aw_lovie {
  width: 50px;
  height: 40px;
  background-image: url("../img/lovie.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.aw_fwa {
  width: 60px;
  height: 40px;
  background-image: url("../img/fwa.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.aw_newmedia {
  width: 45px;
  height: 40px;
  background-image: url("../img/newmedia.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.aw_base {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  margin-left: 10px;
  flex: 0 auto;
}

.aw_base.aw_fwa {
  width: 50px;
  height: 40px;
}

.aw_base.aw_adc {
  width: 40px;
  height: 40px;
}

.aw_base.aw_lovie {
  width: 50px;
  height: 40px;
}

.aw_base.aw_newmedia {
  width: 45px;
  height: 40px;
}

.aw_base.aw_aww {
  width: 70px;
  background-image: url("../img/awww.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.aw_base.aw_nyf {
  width: 30px;
  background-image: url("../img/nyf.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.aw_base.aw_13th {
  background-image: url("../img/13th.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.aw_base.aw_clio {
  width: 60px;
  background-image: url("../img/clio.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.aw_base.aw_dda {
  width: 80px;
  background-image: url("../img/dda.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.cookie-container {
  width: 50%;
  padding-top: 120px;
  padding-bottom: 120px;
  text-align: justify;
}

.cookie-header {
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: F37bobby, sans-serif;
  color: #f10388;
  font-size: 50px;
  line-height: 50px;
  text-align: center;
}

.cookie-text {
  padding-top: 10px;
  padding-bottom: 5px;
  font-family: F37jan, sans-serif;
  color: #dadada;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.cookie-text.padded {
  padding-left: 25px;
}

.cookie-text.padded.bg {
  padding-right: 25px;
  text-align: center;
}

.cookie-header-text {
  padding-top: 20px;
  padding-bottom: 5px;
  font-family: F37jan, sans-serif;
  color: #f10388;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
}

.legalnotice {
  display: none;
}

.header-back-btn {
  position: absolute;
  display: flex;
  margin-left: 10px;
  padding: 10px;
  align-items: center;
  flex: 1;
  transition: color 200ms ease;
  color: #fffcfa;
  text-align: center;
}

.header-back-btn:hover {
  color: #fff;
}

.header-back-btn.hide {
  display: none;
}

.header-back-btn.active {
  color: #f10388;
}

.header-burger-btn-insta {
  display: none;
  width: 25px;
  height: 25px;
  margin-left: 10px;
  flex: 1;
  background-image: url("../img/inst.svg");
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
  -o-object-fit: fill;
  object-fit: fill;
}

.header-burger-btn-seperator {
  position: absolute;
  left: auto;
  top: 41px;
  right: 40px;
  bottom: 0%;
  width: 25px;
  height: 25px;
  margin-top: -9px;
  margin-right: 18px;
  padding-top: 2px;
  padding-right: 0px;
  flex: 0 auto;
  font-family: F37bobbytest;
  color: #f10388;
  font-size: 24px;
  font-weight: 300;
  -o-object-fit: fill;
  object-fit: fill;
}

.link {
  color: #dadada;
}

.link-2 {
  color: #c5c5c5;
}

.header-icon-menu {
  position: absolute;
  right: 40px;
  display: flex;
  height: 25px;
  margin-top: 2px;
  justify-content: flex-end;
  align-items: center;
}

.cookie-wrapper {
  position: fixed;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: 12;
  -o-object-fit: fill;
  object-fit: fill;
}

.cookie-background {
  display: flex;
  width: 33%;
  max-width: 406px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 60px 60px 20px;
  float: right;
  flex-direction: column;
  border-radius: 16px;
  background-color: #000c0a;
}

.btn-parallax {
  display: flex;
  height: 42px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 0px;
  padding-right: 20px;
  padding-left: 20px;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 3px;
  border-color: #f10388;
  border-radius: 150px;
  color: #fff;
  font-size: 16px;
  letter-spacing: 1px;
}

.btn-parallax.hover {
  background-color: #f10388;
}

.btn-parallax.nav-header {
  height: 42px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: rgba(241, 3, 136, 0);
}

.cookie_header {
  display: inline-block;
  overflow: visible;
  transform: translate(0px, 0px);
  font-family: F37bobby, sans-serif;
  color: #f10388;
  font-size: 34px;
  line-height: 34px;
  font-weight: 300;
}

.cookie_header.spacer {
  padding-right: 5px;
  padding-left: 10px;
  color: #f10388;
}

.cookie_header.tmrws {
  transform: translate(0px, 0px);
}

.cookie_header.nohover {
  font-family: F37bobbytest;
  font-weight: 300;
}

.cookie_header.nohover.left {
  padding-left: 0px;
}

.cookie_header.nohover.left.jan {
  font-family: F37jantest;
  color: #f10388;
  font-size: 36px;
}

.cookie_header.mouseover {
  transform: none;
  color: #f10388;
}

.cookie_header.overlay {
  position: absolute;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  width: 150px;
  background-color: #000;
  color: #f10388;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.cookie_header.white {
  color: #fffcfa;
}

.cookie-header-container {
  display: flex;
  margin-bottom: 24px;
  flex-direction: column;
}

.cookie-header-caption {
  margin-bottom: 16px;
  color: #fffcfa;
}

.cookie-consent-text {
  margin-bottom: 24px;
  color: #fffcfa;
  font-size: 12px;
  font-weight: 700;
  text-align: left;
}

.btn-parallax-text {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0px;
  float: left;
  align-items: center;
  align-self: center;
  line-height: 16px;
  letter-spacing: 1px;
}

.cookie-cancel-btn-container {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: flex;
  width: 36px;
  height: 36px;
  margin-top: 20px;
  margin-right: 40px;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: rgba(255, 252, 250, 0.5);
  border-radius: 48px;
  color: #fff;
}

.cookie-cancel-btn-inner {
  width: 38px;
  height: 38px;
  border-style: none;
  border-width: 2px;
  border-color: #f10388;
  border-radius: 48px;
  background-color: #f10388;
}

.btn-parallax-container {
  position: relative;
  display: inline-block;
  margin-right: auto;
  margin-left: auto;
  padding: 24px;
  flex: 0 auto;
  text-align: left;
}

.btn-parallax-container.nav-header-btn-cnt {
  left: 0px;
  margin-right: -24px;
  margin-bottom: -6px;
  margin-left: -24px;
}

.btn-parallax-container.small-p {
  padding: 12px;
}

.btn-parallax-container.nav {
  margin-top: 8px;
  margin-right: -24px;
}

.div-block-8 {
  width: 100%;
  height: 10%;
}

.solution-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.solution-card {
  width: 400px;
  padding-top: 60%;
  border-style: none;
  border-color: #000;
  border-radius: 40px;
  background-image: url("../img/lupe.png");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.solution-card._01 {
  background-image: url("../img/Screenshot-2023-01-08-at-13.20.54.png");
}

.solution-card._03 {
  background-image: url("../img/Screenshot-2023-01-08-at-12.00.07.png");
}

.solution-card._04 {
  background-image: url("../img/UE-Scenes-Export.jpg");
}

.solution-card._05 {
  background-image: url("../img/Reach-Target.jpg");
}

.grid-2 {
  grid-template-areas:
    "Area Area-2"
    "Area-3 Area-4";
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}

.main-el-topic {
  display: inline-block;
  height: 90px;
  padding-left: 2px;
  font-size: 34px;
}

.main-el-topic.overlay {
  position: absolute;
}

.main-el-topic.higher {
  height: 95px;
}

.index-topic {
  position: relative;
  top: -12px;
  display: inline-block;
  font-size: 16px;
}

.index-topic.active {
  color: #f10388;
}

.landing-text-el-topic {
  display: inline-block;
  overflow: visible;
  height: 96px;
  padding-top: 5px;
  padding-bottom: 5px;
  transform: translate(0px, 0px);
  font-family: F37bobby, sans-serif;
  color: #fff;
  font-size: 34px;
  line-height: 86px;
  font-weight: 300;
}

.landing-text-el-topic.spacer {
  padding-right: 5px;
  padding-left: 10px;
  color: #f10388;
}

.landing-text-el-topic.tmrws {
  transform: translate(0px, 0px);
}

.landing-text-el-topic.nohover {
  font-family: F37bobbytest;
  font-weight: 300;
}

.landing-text-el-topic.nohover.left {
  padding-left: 0px;
}

.landing-text-el-topic.nohover.left.jan {
  font-family: F37jantest;
  color: #f10388;
  font-size: 36px;
}

.landing-text-el-topic.mouseover {
  transform: none;
  color: #f10388;
}

.landing-text-el-topic.overlay {
  position: absolute;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  width: 150px;
  background-color: #000;
  color: #f10388;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.solution-container-full {
  display: flex;
  width: 1280px;
}

.div-block-10 {
  padding-right: 60px;
  padding-left: 20px;
}

.solution-player {
  flex: 0 66%;
}

.solution-select {
  display: flex;
  margin-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  justify-content: space-between;
}

.solution-btn {
  display: flex;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  border-style: dashed;
  border-width: 2px;
  border-color: hsla(0, 0%, 100%, 0.5);
  border-radius: 48px;
}

.solution-btn.inner {
  width: 36px;
  height: 36px;
  border-style: none;
  border-width: 0px;
  border-color: hsla(0, 0%, 100%, 0.5);
  background-color: rgba(255, 255, 255, 0.5);
}

.solution-btn.inner.active {
  width: 25px;
  height: 25px;
  border-style: none;
  background-color: #f10388;
}

.solution-btn.active {
  position: relative;
  border-style: solid;
  border-color: #f10388;
}

.player-caption {
  position: absolute;
  left: -35px;
  bottom: -48px;
  width: 120px;
  margin-bottom: 16px;
  color: #fffcfa;
}

.solution-card-full {
  height: 250px;
  padding-top: 60%;
  padding-right: 60px;
  padding-left: 60px;
  border-style: none;
  border-color: #000;
  border-radius: 65px;
  background-image: url("../img/img_cover_52c7c22f-6428-48cd-9116-79a9814f5448.jpg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.solution-card-full._01 {
  background-image: url("../img/Screenshot-2023-01-08-at-13.20.54.png");
}

.solution-card-full._03 {
  background-image: url("../img/Screenshot-2023-01-08-at-12.00.07.png");
}

.solution-card-full._04 {
  background-image: url("../img/UE-Scenes-Export.jpg");
}

.solution-card-full._05 {
  background-image: url("../img/Reach-Target.jpg");
}

.player-desc-caption {
  margin-bottom: 16px;
  color: #fffcfa;
}

.sol-fs-btn-exit {
  display: flex;
  width: 42px;
  height: 42px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: rgba(255, 252, 250, 0.5);
  border-radius: 48px;
  color: #fff;
  font-size: 25px;
}

.autoplay {
  width: 120px;
  margin-bottom: 51px;
  color: #f10388;
  text-align: center;
}

.div-block-12 {
  position: absolute;
  left: 50%;
  display: flex;
  width: 768px;
  height: 48px;
  margin-top: -8px;
  margin-left: -384px;
  padding-top: 0px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 16px;
  background-color: rgba(24, 26, 25, 0.5);
}

.div-block-12.hide {
  display: none;
}

.div-block-13 {
  width: 25px;
  height: 25px;
  margin-left: 20px;
  padding-left: 0px;
  background-image: url("../img/lupe.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.5;
}

.text-block-10 {
  padding-right: 20px;
  padding-left: 20px;
  flex: 0 0 auto;
  color: hsla(0, 0%, 100%, 0.5);
  font-weight: 700;
}

.div-block-14 {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  border-radius: 16px;
  background-color: rgba(0, 12, 10, 0.5);
}

.text-block-11 {
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #fffcfa;
  border-radius: 25px;
  color: #fffcfa;
}

.solution-desc-wrapper {
  display: flex;
  flex: 0 33%;
}

.div-block-16 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.div-block-17 {
  width: 12px;
  height: 12px;
  margin-bottom: 16px;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 16px;
}

.div-block-18 {
  display: none;
  width: 100%;
  height: 50px;
  margin-bottom: 60px;
  justify-content: center;
  align-items: center;
  opacity: 1;
  font-family: F37bobby, sans-serif;
  color: #fffcfa;
  font-weight: 300;
}

.solution-playlist {
  display: inline-block;
  margin-right: 16px;
  font-family: F37bobby, sans-serif;
  color: #fffcfa;
  font-size: 32px;
}

.solution-playlist.active {
  color: #f10388;
}

.text-block-12 {
  display: inline-block;
  margin-right: 16px;
}

.div-block-19 {
  margin-right: 16px;
  padding: 10px;
  border-radius: 20px;
  background-color: #f10388;
  font-family: F37jan, sans-serif;
}

.div-block-20 {
  display: flex;
}

.div-block-21 {
  display: none;
  padding: 40px 40px 116px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(255, 252, 250, 0.05);
  border-radius: 32px;
  background-color: #000c0a;
}

.slider {
  width: 100%;
}

.slide_webflow {
  display: inline-block;
  width: 33.33%;
  height: 150px;
  float: none;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  background-color: #f10388;
  white-space: normal;
}

.solution-fs-wrapper {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  -o-object-fit: fill;
  object-fit: fill;
}

.sol-fs-txt-container {
  display: none;
  transform: translate(0px, 0px);
  font-family: F37bobby, sans-serif;
  color: #f10388;
  font-size: 77px;
  line-height: 77px;
  font-weight: 300;
}

.sol-fs-txt-container.spacer {
  padding-right: 5px;
  padding-left: 10px;
  color: #f10388;
}

.sol-fs-txt-container.tmrws {
  transform: translate(0px, 0px);
}

.sol-fs-txt-container.nohover {
  font-family: F37bobbytest;
  font-weight: 300;
}

.sol-fs-txt-container.nohover.left {
  padding-left: 0px;
}

.sol-fs-txt-container.nohover.left.jan {
  font-family: F37jantest;
  color: #f10388;
  font-size: 36px;
}

.sol-fs-txt-container.mouseover {
  transform: none;
  color: #f10388;
}

.sol-fs-txt-container.overlay {
  position: absolute;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  width: 150px;
  background-color: #000;
  color: #f10388;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.sol-fs-index {
  position: relative;
  top: -40px;
  display: inline-block;
  font-size: 20px;
}

.sol-fs-txt-main {
  display: inline-block;
}

.sol-fs-txt-main.overlay {
  position: absolute;
}

.sol-fs-txt-main.higher {
  height: 95px;
}

.sol-fs-bg-img {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: -1;
  display: none;
  background-image: url("../img/img_cover_52c7c22f-6428-48cd-9116-79a9814f5448.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.sol-fs-bg-img.hide {
  display: none;
}

.sol-fs-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  transform: translate(0px, 0px);
  color: #fffcfa;
}

.text-block-13 {
  color: #fffcfa;
}

.sol-fs-txt-desc {
  margin-bottom: 32px;
  font-size: 12px;
  line-height: 16px;
}

.sol-fs-txt-cat {
  margin-bottom: 16px;
}

.sol-fs-b {
  display: flex;
  height: auto;
  margin-bottom: 10%;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
}

.sol-fs-btn-hover {
  position: relative;
  display: flex;
  width: 42px;
  height: 42px;
  min-width: 32px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: rgba(255, 252, 250, 0.25);
  border-radius: 48px;
  transition: border 200ms ease;
  color: #fff;
  font-size: 16px;
}

.sol-fs-btn-hover.arrow {
  border-style: none;
  color: hsla(0, 0%, 100%, 0.25);
}

.sol-fs-btn-hover.active {
  border-color: rgba(255, 252, 250, 0);
}

.sol-fs-btn-cnt {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 110px;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
}

.div-block-23 {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 3px;
  border-color: #fffcfa;
  border-radius: 20px;
  background-color: #fff;
  color: #f10388;
  text-align: center;
}

.hover-btn-circle {
  display: flex;
  overflow: hidden;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-style: none;
  border-width: 3px;
  border-color: #fffcfa;
  border-radius: 20px;
  transition: background-color 200ms ease;
}

.hover-btn-circle.arrow {
  border-style: none;
  border-width: 2px;
}

.hover-btn-circle.active {
  border-color: #f10388;
  background-color: #f10388;
}

.hover-btn-circle.hide {
  border-style: none;
}

.hover-btn-half-out {
  overflow: hidden;
  width: 18px;
  height: 18px;
  border-radius: 20px;
}

.hover-btn-half-fill {
  width: 18px;
  height: 18px;
  background-color: #fffcfa;
  transform: translate(8px, 0px);
}

.sol-fs-content-wrapper {
  display: flex;
  width: 980px;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  transform: translate(0px, 0px);
}

.sol-fs-exit-btn-cnt {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: flex;
  width: 100%;
  margin-top: 20px;
  padding-right: 0px;
  flex: 1 0 auto;
}

.div-block-26 {
  display: flex;
  align-items: flex-end;
}

.div-block-27 {
  margin-top: -8px;
  flex: 0 0 auto;
}

.div-block-28 {
  padding-left: 16px;
}

.sol-fs-txt {
  padding-bottom: 30px;
  font-family: F37jan, sans-serif;
  color: #fffcfa;
  font-size: 56px;
  line-height: 56px;
  font-weight: 700;
}

.text-span-4 {
  font-family: F37bobby, sans-serif;
  color: #f10388;
}

.text-span-5 {
  color: #fffcfa;
}

.text-span-6 {
  color: #fffcfa;
}

.div-block-29 {
  overflow: hidden;
  margin-bottom: 25px;
  flex: 1;
}

.sol-fs-txt-wrapper {
  display: flex;
  overflow: hidden;
  margin-top: 90px;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
}

.sol-fs-txt-wrapper.hide {
  display: none;
}

.sol-fs-progressbar {
  overflow: hidden;
  width: 33%;
  height: 3px;
  min-width: 200px;
  border-radius: 6px;
  background-color: rgba(255, 252, 250, 0.25);
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
}

.sol-title-container {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 72px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transform: translate(0px, 0px);
}

.sol-title-container.hide {
  display: none;
}

.sol-title-slider-item {
  display: inline-block;
  overflow: visible;
  height: 72px;
  padding: 5px 10px;
  float: left;
  clear: left;
  transform: translate(0px, 0px);
  color: #fffcfa;
  font-size: 56px;
  line-height: 56px;
}

.sol-title-slider-item.spacer {
  padding-right: 5px;
  padding-left: 10px;
  color: #f10388;
}

.sol-title-slider-item.tmrws {
  transform: translate(0px, 0px);
}

.sol-title-slider-item.nohover {
  font-family: F37bobbytest;
  font-weight: 300;
}

.sol-title-slider-item.nohover.left {
  padding-left: 0px;
}

.sol-title-slider-item.nohover.left.jan {
  font-family: F37jantest;
  color: #f10388;
  font-size: 36px;
}

.sol-title-slider-item.mouseover {
  transform: none;
  color: #f10388;
}

.sol-title-slider-item.overlay {
  position: absolute;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  width: 150px;
  background-color: #000;
  color: #f10388;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.sol-title-wrapper {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sol-title-wrapper.hide {
  display: none;
}

.sol-main-el {
  display: inline-block;
  height: 66px;
  color: #fffcfa;
}

.sol-main-el.overlay {
  position: absolute;
}

.sol-main-el.higher {
  height: 95px;
}

.sol-index {
  display: inline-block;
  color: #fffcfa;
  font-size: 21px;
  line-height: 21px;
}

.sol-title-mover {
  display: flex;
  flex-direction: column;
  transform: translate(0px, 0px);
  text-align: center;
}

.sol-title-mover.hide {
  display: none;
}

.sol-fs-btn-line {
  width: 3px;
  height: 12px;
  margin-right: 1px;
  margin-left: 1px;
  flex: 0 0 auto;
  border-style: none;
  border-width: 1px;
  border-color: #fffcfa;
  border-radius: 20px;
  background-color: #f10388;
  transition: background-color 200ms ease;
}

.sol-fs-btn-line.active {
  background-color: #fffcfa;
}

.div-block-31 {
  flex: 0 auto;
}

.sol-fs-spacer-cnt {
  display: flex;
  width: 100%;
  height: 3px;
  justify-content: center;
  align-items: center;
}

.sol-fs-progressbar-fill {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: #f10388;
  transform: translate(-100%, 0px);
}

.sol-fs-btn-timeline-spacer {
  width: 3px;
  height: 3px;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 20px;
  background-color: hsla(0, 0%, 100%, 0.25);
}

.sol-fs-btn-timeline-spacer.vertical {
  width: 3px;
  height: 25px;
  margin-right: 16px;
  margin-left: 16px;
  background-color: hsla(0, 0%, 100%, 0.25);
}

.sol-fs-btn-timeline-spacer.hidden {
  flex: 1;
  background-color: hsla(0, 0%, 100%, 0);
}

.sol-fs-btn-timeline-spacer.hidden.compress {
  display: none;
  flex: 0 auto;
}

.sol-fs-btn-parallax-cnt {
  margin-right: -10px;
  margin-left: -10px;
  padding: 10px;
}

.sol-fs-btn-parallax-cnt.arrow {
  margin-right: 0px;
  margin-left: 0px;
}

.sol-fs-headertxt-wrapper {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 60px;
  margin-bottom: 110px;
  color: #f10388;
}

.sol-fs-headertxt {
  font-size: 32px;
  line-height: 32px;
  text-align: center;
}

.div-block-32 {
  display: flex;
  margin-top: 40px;
  justify-content: center;
  align-items: center;
}

.text-block-14 {
  padding-top: 20px;
  align-self: flex-start;
  font-size: 28px;
  line-height: 28px;
}

.div-block-33 {
  width: 50%;
  padding-right: 20px;
  padding-left: 20px;
  flex: 0 0 auto;
}

.div-block-35 {
  width: 50%;
  flex: 0 0 auto;
}

.sol-fs-more-cnt {
  display: flex;
  width: 100%;
  height: 48px;
  padding-bottom: 16px;
  justify-content: center;
  align-items: center;
}

.div-block-36 {
  display: flex;
  margin-right: 10px;
  margin-bottom: -65px;
  margin-left: 10px;
}

.sol-fs-scrollmore {
  position: relative;
  display: flex;
  height: 32px;
  padding-right: 6px;
  padding-left: 6px;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 0px;
  border-color: #fff;
  border-radius: 6px;
}

.sol-fs-scrollmore.active {
  height: 42px;
  padding-right: 12px;
  padding-left: 12px;
  border-style: solid;
  border-width: 2px;
  border-color: rgba(255, 252, 250, 0.25);
  border-radius: 25px;
  color: #fffcfa;
  font-size: 16px;
}

.sol-fs-scrollmore.active.close {
  padding-right: 32px;
  background-image: url("../img/lupe.png");
  background-position: 100% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.sol-fs-scrollmore.active.hide {
  display: none;
}

.sol-fs-scrollmore.active.play-film {
  border-style: solid;
  border-width: 1px;
  border-color: rgba(255, 252, 250, 0.25);
  color: #fffcfa;
}

.div-block-38 {
  width: 6px;
  height: 6px;
  margin-right: 3px;
  margin-left: 3px;
  border-radius: 20px;
  background-color: #fffcfa;
}

.text-block-15 {
  margin-right: 4px;
  margin-left: 7px;
  padding-right: 0px;
}

.text-block-16 {
  transform: translate(0px, 0px) rotate(-90deg);
  color: #fffcfa;
  font-size: 32px;
}

.text-block-16.scrollmore {
  transform: none;
  font-size: 16px;
}

.sol-fs-scrollmore-cnt {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.sol-fs-scrollmore-inner {
  color: #fff;
  font-size: 16px;
}

.article-el {
  display: flex;
  margin-bottom: 0px;
  padding-top: 40px;
  padding-bottom: 40px;
  align-items: center;
  border-bottom: 1px none rgba(255, 252, 250, 0.25);
}

.article-el.ar-header {
  display: block;
  padding-top: 0px;
  padding-right: 20px;
  padding-left: 20px;
  border-bottom-style: none;
}

.article-el.ar-header.heading-desc {
  display: flex;
  margin-top: 8px;
  padding-bottom: 0px;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.article-el.ar-header.work {
  padding-bottom: 0px;
}

.article-el.ar-header.center {
  padding-top: 25vh;
  text-align: center;
}

.article-el.ar-header.center.follow {
  padding-top: 20px;
  padding-bottom: 0px;
}

.article-el.ar-third-left {
  display: flex;
  align-items: center;
}

.article-el.center-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-style: none;
}

.article-el.estimate {
  flex-direction: column;
  justify-content: center;
}

.article-el.estimate.hide {
  display: none;
}

.article-el.breaker {
  width: 100vw;
  margin-left: -752px;
  justify-content: center;
  align-items: stretch;
}

.article-el.breaker._w-p {
  max-width: 1400px;
  margin-top: 120px;
  margin-left: -205px;
  flex-direction: row;
}

.article-el.title-center {
  margin-top: 200px;
  padding-top: 0px;
  padding-bottom: 60px;
  font-size: 80px;
  line-height: 1.0714285714;
  text-align: center;
}

.article-el.center {
  justify-content: center;
  text-align: center;
}

.article-el.no-p-t {
  padding-top: 0px;
  padding-bottom: 200px;
}

.article-el.triple {
  padding-top: 200px;
  padding-bottom: 0px;
}

.ar-item-onethird {
  width: 40%;
  padding-right: 40px;
  padding-left: 20px;
}

.ar-item-onethird.right {
  padding-right: 20px;
  padding-left: 40px;
}

.ar-item-grow {
  position: relative;
  flex: 1 0 auto;
}

.ar-item-grow.breaker-one {
  max-width: 1600px;
}

.ar-item-grow.breaker-three {
  display: flex;
  max-width: 1600px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.ar-item-subheader {
  margin-bottom: 32px;
  font-size: 40px;
  line-height: 40px;
}

.ar-item-subheader.estimate {
  margin-top: 20px;
  margin-bottom: 0px;
  margin-left: -4px;
  color: #f10388;
  font-size: 56px;
  line-height: 56px;
  text-align: center;
}

.ar-item-desc {
  font-family: F37jan, sans-serif;
  color: rgba(255, 252, 250, 0.5);
  font-size: 21px;
  line-height: 25px;
  font-weight: 700;
}

.ar-item-desc.estimate {
  margin-bottom: 20px;
  text-align: center;
}

.ar-item-img {
  height: 400px;
  padding-top: 56.25%;
  background-image: url("../img/img_cover_52c7c22f-6428-48cd-9116-79a9814f5448.jpg");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.ar-item-img.img02 {
  background-image: url("../img/img_02_compressed_resized.png");
  background-position: 0% 50%;
}

.ar-item-img.img04 {
  background-image: url("../img/img_04_compressed_resized.png");
  background-position: 50% 50%;
  background-size: contain;
}

.ar-item-img.img03 {
  background-image: url("../img/img_03_compressed_resized.png");
  background-position: 100% 50%;
  background-size: contain;
}

.ar-item-img._169 {
  background-image: url("../img/img_01_compressed_resized.png");
  background-position: 100% 50%;
}

.ar-item-img.breaker-two {
  width: 33%;
  height: auto;
  padding-top: 20%;
  background-image: url("../img/backdrop.png");
}

.ar-item-img.breaker-three-portrait {
  width: 33%;
  height: auto;
  padding-top: 55%;
  background-image: url("../img/Screenshot-2023-01-08-at-12.00.07.png");
  background-size: cover;
}

.ar-item-img.modular {
  background-image: url("../img/virtual-influencer-modular.png");
  background-size: 800px;
}

.ar-item-img.timeline {
  width: 100vw;
  padding-top: 0%;
  background-image: url("../img/icon_preview_v01.png");
  background-position: 50% 44%;
  background-size: cover;
}

.ar-item-img.round {
  border-radius: 25px;
}

.div-block-39 {
  width: 100%;
  height: 100%;
}

.ar-item-half {
  width: 50%;
  padding-right: 40px;
  padding-left: 20px;
}

.ar-item-half.right {
  padding-right: 20px;
  padding-left: 40px;
}

.ar-item-half.estimate {
  width: 75%;
  padding-right: 20px;
  padding-left: 20px;
}

.text-span-7 {
  color: #f10388;
}

.div-block-40 {
  display: flex;
  height: 32px;
  padding-right: 10px;
  padding-left: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background-color: #f10388;
  font-size: 16px;
}

.text-block-17 {
  color: #fffcfa;
}

.text-span-9 {
  color: #f10388;
}

.text-span-10 {
  color: #f10388;
}

.text-span-11 {
  color: #fffcfa;
}

.text-pink {
  color: #f10388;
}

.text-span-13 {
  color: #fffcfa;
}

.text-white {
  color: #fffcfa;
}

.text-span-15 {
  color: #f10388;
}

.text-span-16 {
  color: #fffcfa;
}

.text-span-17 {
  color: #fffcfa;
}

.header-nav-btn {
  transition: color 200ms ease;
  color: #fff;
  line-height: 16px;
  letter-spacing: 1px;
}

.header-nav-btn:hover {
  color: #fff;
}

.header-nav-cnt {
  display: flex;
  margin-top: -8px;
  justify-content: flex-end;
  flex: 1 0 0px;
}

.nav-header-btn-prim {
  position: relative;
  display: flex;
  height: 40px;
  margin-right: 15px;
  padding-right: 15px;
  padding-left: 15px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.nav-header-btn-prim.no-outline {
  border-style: none;
}

.nav-header-btn-prim.prim {
  border-style: none;
  background-color: #f10388;
}

.nav-header-underline-cnt {
  position: absolute;
  width: 100%;
  height: 2px;
  margin-top: 1px;
  background-color: rgba(255, 252, 250, 0.5);
  color: rgba(255, 252, 250, 0.5);
}

.div-block-42 {
  position: relative;
}

.nav-header-undline-fill {
  width: 100%;
  height: 100%;
  background-color: #fffcfa;
  transform: translate(0px, 0px);
}

.header_v2 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 10;
  display: flex;
  width: 100%;
  height: 100px;
  padding-right: 40px;
  padding-left: 40px;
  justify-content: space-between;
  align-items: center;
}

.header_v2.hide {
  display: none;
}

.header-center-btn-cnt {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fffcfa;
  text-align: center;
}

.header-center-btn-cnt.hide {
  display: none;
}

.sol-fs-txt-wrapper-v2 {
  position: absolute;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.sol-fs-txt-v2 {
  display: flex;
  overflow: hidden;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  font-family: F37jan, sans-serif;
  color: #fffcfa;
  font-size: 56px;
  line-height: 56px;
  font-weight: 700;
}

.sol-fs-txt-v2.scroll {
  overflow: scroll;
}

.fs-txt-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.sol-fs-btn-parallax-cnt-arrow {
  margin-right: 0px;
  margin-left: 0px;
  padding: 10px;
  opacity: 0;
  transform: none;
  transition: transform 200ms ease, opacity 200ms ease;
}

.sol-fs-btn-parallax-cnt-arrow.arrow {
  margin-right: 0px;
  margin-left: 0px;
}

.sol-fs-btn-parallax-cnt-arrow.active {
  opacity: 1;
  transform: translate(0%, 0px);
  transition: transform 200ms ease, opacity 200ms ease;
}

.sol-fs-btn-parallax-cnt-arrow.left {
  transform: translate(100%, 0px);
}

.sol-fs-btn-parallax-cnt-arrow.left.active {
  transform: translate(0%, 0px);
}

.sol-fs-btn-parallax-cnt-arrow.right {
  transform: translate(-100%, 0px);
}

.sol-fs-btn-parallax-cnt-arrow.right.active {
  transform: translate(0%, 0px);
}

.circle-x {
  display: flex;
  width: 25px;
  height: 25px;
  margin-right: 6px;
  margin-left: -6px;
  padding-bottom: 2px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-image: url("../img/x_circle_pink.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  text-align: center;
}

.circle-x.play-film-icon {
  position: relative;
  background-image: url("../img/Remote_pink.png");
}

.circle-x.play-film-icon.overlay {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  margin-right: 0px;
  margin-left: 0px;
  padding-bottom: 0px;
  background-image: url("../img/Remote_white.png");
}

.circle-dots {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-image: url("../img/dotted-half-circle2x.png");
  background-position: 50% 50%;
  background-size: 32px 32px;
  background-repeat: no-repeat;
}

.debug-overlay {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 10000000;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
}

.debug-overlay.hide {
  display: none;
}

.debug-txt {
  display: inline-block;
  padding: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 20px;
  background-color: #181919;
  color: #fff;
}

.debug-item {
  height: 20px;
}

.complications-wrapper {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 12vh;
  padding-bottom: 12vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.complications-wrapper.hide {
  display: none;
}

.complication-el {
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 16px 16px 8px;
  -ms-grid-row-align: center;
  align-self: center;
  border-radius: 25px;
  background-color: rgba(33, 33, 43, 0.95);
  box-shadow: 0 50px 50px 0 rgba(0, 0, 0, 0.25);
  color: #fffcfa;
  font-size: 16px;
}

.complication-el.connection {
  position: relative;
  margin-top: 10px;
  padding-bottom: 0px;
  font-size: 16px;
}

.complication-el.connection.left {
  margin-left: -25%;
}

.complication-el.recording {
  position: relative;
  width: 152px;
  background-color: rgba(33, 33, 43, 0.95);
}

.complication-el.recording.hide {
  display: none;
}

.complication-el.align-left {
  margin-left: 50%;
  align-self: flex-start;
}

.complication-el.align-left.hide {
  display: none;
}

.complication-el.align-right {
  margin-right: 50%;
  align-self: flex-end;
}

.complication-el.align-right.hide {
  display: none;
}

.complication-el.transparent {
  background-color: rgba(33, 33, 43, 0.5);
}

.complication-el.hide {
  display: none;
}

.complication-el.full-transparent {
  background-color: rgba(33, 33, 43, 0);
}

.complication-el.full-transparent.msg {
  width: 50%;
  max-width: 960px;
  box-shadow: none;
}

.complication-title-cnt {
  display: flex;
  margin-bottom: 4px;
  align-items: center;
}

.complication-title {
  padding-bottom: 0px;
  flex: 1;
  font-size: 13px;
  line-height: 13px;
}

.complication-title.green {
  color: #68ce67;
}

.complication-title.pink {
  color: #f10388;
}

.complication-title.purple {
  color: #935dea;
}

.complication-title.grey {
  color: rgba(255, 252, 250, 0.6);
}

.complication-title.start {
  padding-top: 4px;
}

.complication-logo {
  width: 13px;
  height: 13px;
  margin-right: 6px;
  background-image: url("../img/checkmark-green.png");
  background-position: 50% 50%;
  background-size: cover;
}

.complication-logo.record {
  background-image: url("../img/watch.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.complication-logo.playback {
  background-image: url("../img/playback.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.complication-logo.microphone {
  background-image: url("../img/microphone_new.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.complication-logo.ai {
  background-image: url("../img/ai-icon.png");
}

.complication-logo.loop {
  background-image: url("../img/loop.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.compilation-row {
  position: relative;
  display: flex;
  margin-bottom: 4px;
  flex-direction: column;
}

.compilation-row.center {
  align-items: center;
}

.compilation-row.horizontal {
  flex-direction: row;
}

.compilation-row.timeline {
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: flex-start;
  transform-style: preserve-3d;
}

.compilation-row.msg {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 40px;
  align-items: flex-start;
  transform-style: preserve-3d;
}

.compilation-row.msg.right {
  align-items: flex-end;
}

.complications-status-cnt {
  display: flex;
  margin-top: 4px;
  margin-bottom: 5px;
  align-items: center;
}

.status-led {
  width: 6px;
  height: 9px;
  margin-right: 3px;
  border-radius: 2px;
  background-color: #606067;
}

.status-led.full {
  height: 12px;
}

.complication-record-time {
  height: 32px;
  padding-top: 6px;
  align-self: flex-start;
  font-size: 32px;
  line-height: 16px;
}

.complication-record-time.green {
  color: #68ce67;
}

.complication-record-time.pink {
  color: #f10388;
}

.record-btn {
  position: relative;
  display: flex;
  width: 56px;
  height: 56px;
  justify-content: center;
  align-items: center;
}

.record-btn-inner {
  width: 28.8px;
  height: 28.8px;
  border-radius: 36px;
  background-color: #f10388;
}

.record-btn-inner.purple {
  background-color: #935dea;
}

.status-slider {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 200px;
  height: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-right: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background-color: #606067;
}

.status-slider.full {
  height: 15px;
}

.compilation-slider-cnt {
  display: flex;
  flex-direction: column;
}

.compilation-slider-cnt.center {
  align-items: center;
}

.compilation-slider-cnt.horizontal {
  flex-direction: row;
}

.complication-slider-txt-cnt {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.complication-slider-heading {
  position: relative;
  top: 4px;
  height: 14px;
  margin-bottom: 8px;
  padding-right: 16px;
  color: rgba(255, 252, 250, 0.6);
  font-size: 13px;
  line-height: 13px;
  white-space: nowrap;
}

.complication-slider-heading.green {
  color: #68ce67;
}

.complication-slider-heading.pink {
  color: #f10388;
}

.complication-slider-heading.purple {
  color: #935dea;
}

.complication-slider-heading.grey {
  color: rgba(255, 252, 250, 0.6);
}

.complications-slider-indicator {
  position: relative;
  width: 6px;
  height: 12px;
  border-radius: 2px;
  background-color: rgba(255, 252, 250, 0.6);
}

.complications-slider-mover {
  position: relative;
  width: 100%;
}

.complications-slider-txt {
  position: absolute;
  margin-left: 4px;
  color: rgba(255, 252, 250, 0.6);
  font-size: 10px;
}

.record-btn-outer {
  position: absolute;
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 5px;
  border-color: rgba(241, 3, 136, 0.5);
  border-radius: 56px;
}

.record-btn-outer.purple {
  border-color: rgba(147, 93, 234, 0.6);
}

.complication-spacer {
  flex: 1;
}

.complication-spacer.hide {
  display: none;
}

.comp-wave-cnt {
  position: relative;
  height: 50px;
  margin-top: 8px;
}

.comp-waveform {
  position: absolute;
  width: 100%;
  height: 50px;
  background-image: url("../img/waveform.png");
  background-position: 0% 50%;
  background-size: 390px;
  background-repeat: no-repeat;
}

.comp-waveform.bg {
  width: 100%;
  opacity: 0.35;
}

.comp-waveform.bg.baseline {
  background-image: url("../img/waveform-baseline.png");
  background-position: 0% 50%;
  background-size: 390px;
  background-repeat: no-repeat;
}

.comp-shot-track {
  position: relative;
  left: 0%;
}

.comp-music-track {
  position: relative;
  height: 32px;
}

.comp-shot {
  display: inline-block;
  height: 32px;
  margin-right: 5px;
  padding-top: 8px;
  padding-right: 10px;
  padding-left: 10px;
  justify-content: center;
  align-items: center;
  border: 2px solid transparent;
  border-radius: 7px;
  background-color: rgba(255, 252, 250, 0.1);
  font-size: 13px;
  line-height: 13px;
}

.comp-shot.active {
  background-color: #935dea;
}

.comp-shot.active.background {
  width: 370px;
  margin-left: 60px;
  border-style: none;
  border-radius: 7px;
  background-image: url("../img/timeline-waveform.png");
  background-position: 50% 105%;
  background-size: auto 20px;
  background-repeat: no-repeat;
}

.comp-timeline-indicator {
  position: absolute;
  left: 50%;
  top: -14px;
  width: 2px;
  height: 100px;
  margin-left: -1px;
  border-radius: 5px;
  background-color: #fffcfa;
}

.track-cnt {
  overflow: hidden;
  width: 100%;
  margin-bottom: 8px;
  border-radius: 7px;
}

.back-icon {
  display: none;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  padding-left: 10px;
  background-image: url("../img/arrow-left.png");
  background-position: 0% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.back-icon.active {
  background-image: url("../img/arrow-left-pink.png");
}

.back-icon.arrow {
  display: block;
}

.back-icon.arrow.cross {
  background-image: url("../img/x-white.png");
}

.back-txt-cnt {
  position: relative;
  margin-right: 10px;
  white-space: nowrap;
}

.back-txt-cnt.underline {
  position: relative;
}

.back-txt-cnt.active {
  color: #fffcfa;
}

.back-txt-cnt.small {
  color: #fffcfa;
  font-size: 12px;
}

.div-block-44 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  margin-bottom: -1px;
  border-bottom: 2px solid rgba(255, 252, 250, 0.5);
}

.back-txt.active {
  color: #f10388;
}

.back-txt.spacer {
  padding-top: 2px;
  color: rgba(255, 252, 250, 0.5);
  font-size: 12px;
}

.back-txt.transparent {
  font-family: F37jan, sans-serif;
}

.back-txt.transparent.alt {
  font-size: 16px;
}

.text-white-header {
  display: inline;
  color: #fffcfa;
  -moz-column-count: 15;
  column-count: 15;
}

.scroll-indicator {
  position: relative;
  width: 32px;
  height: 42px;
  background-image: url("../img/swipe_gesture.png");
  background-position: 0px 0px;
  background-size: contain;
  background-repeat: no-repeat;
}

.text-white-header-index {
  display: inline-block;
  font-size: 21px;
  line-height: 21px;
}

.slidetest {
  width: 36.54843vw;
  padding-top: 17.25%;
  background-color: rgba(33, 33, 43, 0.95);
}

.slidetest.more {
  border: 1px solid #000;
  border-radius: 1px;
}

.slider-desc-main {
  color: #fffcfa;
  font-size: 32px;
  line-height: 32px;
}

.slider-desc-main.overlay {
  position: absolute;
}

.slider-desc-main.higher {
  height: 95px;
}

.slider-index {
  color: #fffcfa;
  font-size: 16px;
  line-height: 16px;
}

.slider-txt-cnt {
  display: flex;
  width: 100%;
  margin-top: 15px;
  flex-direction: column;
  align-items: flex-start;
  opacity: 0.5;
  transition: opacity 200ms ease;
}

.slider-txt-cnt.hover {
  opacity: 1;
}

.slider-title-cnt {
  display: flex;
  width: 100%;
  align-items: flex-start;
}

.slider-tag-new {
  display: flex;
  margin-top: 7px;
  margin-right: 8px;
  margin-left: 0px;
  padding: 5px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: rgba(255, 252, 250, 0.15);
  color: #fffcfa;
  font-size: 16px;
  line-height: 16px;
  text-transform: capitalize;
}

.slider-tag-new.pink {
  background-color: rgba(241, 3, 136, 0.15);
  color: #f10388;
}

.slider-tag-new.work {
  background-color: rgba(147, 93, 234, 0.15);
  color: #935dea;
}

.slider-tag-new.solution {
  background-color: rgba(241, 3, 136, 0.15);
  color: #f10388;
}

.slider-tag-new.solution.header-back {
  height: 42px;
  background-color: #181919;
  color: #fffcfa;
}

.slider-tag-new.no-bg {
  margin-right: 0px;
  padding-right: 4px;
  padding-left: 4px;
  background-color: rgba(255, 252, 250, 0);
}

.slider-tag-new.more {
  border-style: solid;
  border-width: 2px;
  border-color: #fffcfa;
  background-color: transparent;
}

.slider-tag-new.solution-slide {
  background-color: rgba(191, 2, 108, 0.25);
  color: #f10388;
}

.slider-tag-new.solution-slide.header-back {
  height: 42px;
  background-color: #181919;
  color: #fffcfa;
}

.slider-tag-new.work-slide {
  background-color: rgba(120, 76, 191, 0.25);
  color: #935dea;
}

.div-block-45 {
  width: 16px;
  height: 16px;
  background-image: url("../img/solutions_pink.png");
  background-position: 0px 0px;
  background-size: contain;
  background-repeat: no-repeat;
}

.div-block-45.work {
  background-image: url("../img/Remote_purple.png");
}

.div-block-45.chevron {
  background-image: url("../img/chevron-down_white.png");
}

.div-block-45.more {
  background-image: url("../img/chevron-down_pink.png");
  background-position: 0px 1px;
  background-size: contain;
}

.slider-desc-main-under {
  margin-left: 14px;
  color: rgba(255, 252, 250, 0.5);
  font-size: 16px;
  line-height: 16px;
}

.slider-desc-main-under.overlay {
  position: absolute;
}

.slider-desc-main-under.higher {
  height: 95px;
}

.slider-tag-new-txt {
  padding-right: 5px;
  padding-left: 5px;
  white-space: nowrap;
}

.play-btn-wrapper {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.play-btn-wrapper.hide {
  display: none;
}

.play-btn {
  display: flex;
  width: 64px;
  height: 64px;
  background-image: url("../img/play-btn.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.rotate-screen-wrapper {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: none;
}

.div-block-46 {
  position: relative;
  display: flex;
  height: 42px;
  padding-right: 15px;
  padding-left: 15px;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(255, 252, 250, 0.25);
  border-radius: 20px;
  color: #fffcfa;
}

.div-block-47 {
  width: 20px;
  height: 20px;
  margin-right: 15px;
  border-radius: 0px;
  background-image: url("../img/solutions_pink.png");
  background-position: 0px 0px;
  background-size: contain;
  opacity: 1;
}

.div-block-47.work {
  background-image: url("../img/Remote_purple.png");
}

.div-block-48 {
  position: absolute;
  z-index: -1;
  width: 85px;
  height: 32px;
  border-radius: 20px;
  background-color: #fffcfa;
  transform: translate(-10px, 0px);
}

.text-block-20 {
  margin-right: 15px;
  color: #181919;
}

.text-block-21 {
  color: rgba(255, 252, 250, 0.5);
}

.searchbar-header-cnt {
  position: relative;
  display: flex;
  height: 40px;
  max-width: 440px;
  align-items: center;
  flex: 1;
  color: #fffcfa;
}

.searchbar-header-cnt.hide {
  display: none;
}

.header-searchbar {
  height: 40px;
}

.header_v3 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 10;
  display: flex;
  width: 100%;
  height: 100px;
  padding-right: 40px;
  padding-left: 40px;
  justify-content: space-between;
  align-items: center;
}

.header_v3.hide {
  display: none;
}

.header-logo-cnt-v3 {
  position: relative;
  width: 100px;
  height: 29px;
  flex: 1 0 0px;
}

.header-nav-cnt-v3 {
  display: flex;
  margin-top: -8px;
  justify-content: flex-end;
  flex: 1 0 0px;
}

.header-burger-btn-insta-v3 {
  position: relative;
  z-index: 1;
  width: 35px;
  height: 25px;
  margin-top: 8px;
  margin-right: -12px;
  margin-left: 2px;
  flex: 0 0 auto;
  background-image: url("../img/inst.svg");
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
  -o-object-fit: fill;
  object-fit: fill;
}

.searchbar-logo {
  width: 52px;
  height: 40px;
  border-radius: 16px;
  background-image: url("../img/lupe.png");
  background-position: 50% 50%;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  opacity: 0.5;
}

.searchbar-tag-bar {
  margin-right: 6px;
  padding: 3px 12px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(255, 252, 250, 0);
  border-radius: 20px;
  background-color: rgba(255, 252, 250, 0.15);
}

.searchbar-drop-down {
  position: absolute;
  top: 0px;
  z-index: -1;
  display: flex;
  width: 100%;
  padding-top: 60px;
  padding-right: 16px;
  padding-left: 16px;
  flex-direction: column;
  border-radius: 20px 20px 8px 8px;
  background-color: rgba(33, 33, 43, 0.25);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.searchbar-drop-down.hide {
  display: none;
}

.searchbar-dropdown-title {
  margin-bottom: 0px;
  color: rgba(255, 252, 250, 0.25);
  font-size: 13px;
}

.searchbar-filter-row {
  display: flex;
  margin-bottom: 16px;
  flex-wrap: wrap;
}

.searchbar-filter-item {
  margin-top: 6px;
  margin-right: 8px;
  margin-bottom: 6px;
  padding: 6px 12px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(255, 252, 250, 0.15);
  border-radius: 20px;
  transition: color 200ms ease, border 200ms ease;
  color: rgba(255, 252, 250, 0.5);
}

.searchbar-filter-item.all {
  color: rgba(255, 252, 250, 0.5);
}

.searchbar-filter-item.active {
  border-style: solid;
  border-color: rgba(255, 252, 250, 0.15);
  background-color: rgba(255, 252, 250, 0.15);
  color: #fffcfa;
}

.searchbar-filter-item.hover {
  border-color: #fffcfa;
  color: #fffcfa;
}

.searchbar-input {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: flex;
  align-items: center;
  border-style: none;
  border-width: 2px;
  border-color: rgba(255, 252, 250, 0.5);
  border-radius: 20px;
  background-color: rgba(255, 252, 250, 0.15);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}

.ar-item-subheader-spacer {
  height: 32px;
  font-size: 40px;
  line-height: 40px;
}

.ar-item-subheader-spacer.estimate {
  margin-top: 20px;
  margin-bottom: 0px;
  margin-left: -4px;
  color: #f10388;
  font-size: 56px;
  line-height: 56px;
  text-align: center;
}

.video-modal {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
}

.video-content {
  position: relative;
  overflow: hidden;
  width: 100vw;
  max-width: 1280px;
  min-width: 320px;
  border-radius: 8px;
}

.video-btn-wrapper {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.15);
  background-image: radial-gradient(
    circle farthest-corner at 50% 50%,
    transparent,
    rgba(0, 0, 0, 0.25)
  );
  transition: background-color 200ms ease;
}

.vid-btn-play {
  position: absolute;
  left: 50%;
  bottom: 50%;
  width: 75px;
  height: 75px;
  background-image: url("../img/remote_pink_white.png");
  background-position: 0px 0px;
  background-size: contain;
  transform: translate(-50%, 50%);
}

.vid-btn-play.pause {
  background-image: url("../img/pause-icon.png");
  background-position: 50% 50%;
  background-size: 56px;
  background-repeat: no-repeat;
}

.vid-btn-play.play {
  background-image: url("../img/play-icon.png");
  background-position: 50% 50%;
  background-size: 56px;
  background-repeat: no-repeat;
}

.vid-btn-play.play.grad {
  background-image: url("../img/play_icon_grad.png");
}

.vid-btn-loading {
  position: absolute;
  left: 50%;
  bottom: 50%;
  width: 75px;
  height: 75px;
  background-image: url("../img/watch.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  transform: translate(-50%, 50%);
}

.vid-btn-loading.pause {
  background-image: url("../img/x-white.png");
  background-position: 0px 0px;
  background-size: auto;
}

.vid-lower-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vid-main-controls {
  display: flex;
  width: 100%;
  height: 45px;
  max-width: 980px;
  justify-content: center;
}

.vid-main-btn-wrapper {
  height: 100%;
  opacity: 0.5;
  transition: opacity 200ms ease;
}

.vid-main-btn-wrapper.hover {
  opacity: 1;
}

.vid-main-button {
  width: 35px;
  height: 33px;
}

.vid-main-button.mute {
  background-image: url("../img/mute-on.png");
  background-position: 50% 50%;
  background-size: 22px;
  background-repeat: no-repeat;
}

.vid-main-button.mute.off {
  background-image: url("../img/mute-off.png");
}

.vid-main-button.fullscreen {
  background-image: url("../img/fullscreen.png");
  background-position: 50% 50%;
  background-size: 22px;
  background-repeat: no-repeat;
}

.vid-main-button.mute-off {
  background-image: url("../img/mute-off.png");
  background-position: 50% 50%;
  background-size: 22px;
  background-repeat: no-repeat;
}

.vid-main-control-progress-cnt {
  display: flex;
  height: 35px;
  margin-right: 16px;
  margin-left: 16px;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
}

.controls-elapsed-time-indicator {
  color: #fffcfa;
}

.elapsed-time-txt {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  letter-spacing: 1px;
}

.vid-progress-cnt {
  display: flex;
  height: 100%;
  margin-right: 10px;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.vid-progress-track {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 5px;
  border-radius: 3px;
  background-color: rgba(255, 252, 250, 0.16);
}

.vid-progress-fill {
  height: 100%;
  border-radius: 3px;
  background-color: #fffcfa;
}

.vid-progress-hover-indicator {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 1px;
  height: 100%;
  background-color: #181919;
  transform: translate(210px, 0px);
}

.vid-progress-hover-indicator.inactive {
  background-color: #fff;
  transform: translate(0px, 0px);
}

.vid-overlay {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-image: radial-gradient(
    circle farthest-corner at 50% 50%,
    rgba(0, 0, 0, 0.5),
    #000
  );
  opacity: 0.25;
  transition: opacity 200ms ease;
}

.modal-vid-aspect {
  max-height: 100%;
  padding-top: 56.25%;
}

.tag-slide-icon {
  width: 16px;
  height: 16px;
  background-image: url("../img/solutions_pink.png");
  background-position: 0px 0px;
  background-size: contain;
  background-repeat: no-repeat;
}

.tag-slide-icon.work {
  background-image: url("../img/Remote_purple.png");
}

.tag-slide-icon.chevron {
  background-image: url("../img/chevron-down_white.png");
}

.tag-slide-icon.more {
  background-image: url("../img/chevron-down_pink.png");
  background-position: 0px 1px;
  background-size: contain;
}

.scrollwrapper {
  display: flex;
  flex-direction: column;
}

.scrollwrapper.hide {
  display: none;
}

.home-content-el {
  height: 50vh;
  background-color: #f10388;
}

.landing-scroll-el {
  width: 100%;
}

.ar-item-full {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
}

.ar-item-full.right {
  padding-right: 20px;
  padding-left: 40px;
}

.ar-item-full.estimate {
  width: 75%;
  padding-right: 20px;
  padding-left: 20px;
}

.section-title-sub {
  display: inline-block;
  color: #fffcfa;
  font-size: 45px;
  line-height: 1.0714285714;
}

.section-title-sub.estimate {
  margin-top: 20px;
  margin-bottom: 0px;
  margin-left: -4px;
  color: #f10388;
  font-size: 56px;
  line-height: 56px;
  text-align: center;
}

.section-title-sub.center {
  text-align: center;
}

.section-title-sub.router {
  max-width: 450px;
}

.section-title-sub.bottom-padding {
  padding-bottom: 0.8em;
}

.section-description {
  margin-top: 40px;
  font-family: F37jan, sans-serif;
  color: #a1a1a6;
  font-size: 24px;
  line-height: 1.125;
  font-weight: 700;
  text-align: left;
}

.section-description.estimate {
  margin-bottom: 20px;
  text-align: center;
}

.section-description.no-p-t {
  margin-top: 0em;
}

.section-description.router {
  margin-top: 0em;
  padding-bottom: 0.8em;
  color: #fffcfa;
}

.section-description.center {
  -ms-grid-row-align: center;
  align-self: center;
  text-align: center;
}

.section-description.center.contact {
  max-width: 600px;
}

.router-item {
  display: flex;
  overflow: hidden;
  width: 50%;
  margin-right: 0px;
  margin-left: 0px;
  padding-top: 52px;
  padding-right: 0px;
  padding-left: 0px;
  flex-direction: column;
  align-items: center;
  flex: 0 auto;
  border-style: none;
  border-width: 3px;
  border-color: #fffcfa;
  border-radius: 25px;
  background-color: #181919;
  text-align: center;
}

.router-item.black {
  background-color: #000;
}

.router-item.full {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
  padding-top: 0px;
  align-items: center;
  background-color: transparent;
}

.router-item.left {
  margin-right: 5px;
}

.router-item.right {
  margin-left: 5px;
}

.router-desc {
  max-width: 400px;
  font-size: 45px;
}

.router-desc.full {
  text-align: left;
}

.router-title {
  margin-bottom: 5px;
  font-size: 24px;
  line-height: 24px;
}

.router-img {
  width: 100%;
  height: 500px;
  margin-top: 0px;
  margin-bottom: 0px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background-image: url("../img/landing-router-sol.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.router-img.work {
  padding-bottom: 0px;
  background-image: url("../img/landing-router-work.png");
  background-size: contain;
}

.router-img.swipe {
  background-image: url("../img/landing-router-swipe.png");
  background-position: 50% 50%;
}

.section-router-spacer {
  flex: 1;
}

.router-row {
  display: flex;
  flex-direction: row;
}

.router-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.section-div {
  display: flex;
  width: 100%;
  max-width: 1440px;
  margin-bottom: 0px;
  padding: 200px 20px 0px;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.section-div.ar-header {
  display: block;
  padding-top: 0px;
  padding-right: 20px;
  padding-left: 20px;
  border-bottom-style: none;
}

.section-div.ar-header.heading-desc {
  display: flex;
  margin-top: 8px;
  padding-bottom: 0px;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.section-div.ar-header.work {
  padding-bottom: 0px;
}

.section-div.ar-header.center {
  padding-top: 25vh;
  text-align: center;
}

.section-div.ar-header.center.follow {
  padding-top: 20px;
  padding-bottom: 0px;
}

.section-div.ar-third-left {
  display: flex;
  align-items: center;
}

.section-div.center-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-style: none;
}

.section-div.estimate {
  flex-direction: column;
  justify-content: center;
}

.section-div.estimate.hide {
  display: none;
}

.section-div.breaker {
  width: 100vw;
  margin-left: -752px;
  justify-content: center;
  align-items: stretch;
}

.section-div.breaker._w-p {
  max-width: 1400px;
  margin-top: 120px;
  margin-left: -205px;
  flex-direction: row;
}

.section-div.title-center {
  margin-top: 200px;
  padding-top: 0px;
  padding-bottom: 60px;
  font-size: 80px;
  line-height: 1.0714285714;
  text-align: center;
}

.section-div.center {
  justify-content: center;
  text-align: center;
}

.section-div.no-p-t {
  padding-top: 0px;
  padding-bottom: 200px;
}

.section-div.triple {
  padding-top: 200px;
  padding-bottom: 0px;
}

.section-div.full-width {
  width: 100%;
  max-width: none;
  padding-top: 40px;
}

.section-div.bigger {
  max-width: 1400px;
  padding-right: 20px;
  padding-left: 20px;
}

.section-div.inner {
  padding-top: 0px;
}

.section-div.grow-universe {
  padding-top: 100px;
}

.section-div.no-p {
  padding-top: 0px;
}

.section-hero {
  width: 100%;
  max-width: 980px;
  align-self: flex-start;
  font-size: 56px;
  line-height: 1.0714285714;
  text-align: left;
}

.section-hero.estimate {
  margin-top: 20px;
  margin-bottom: 0px;
  margin-left: -4px;
  color: #f10388;
  font-size: 56px;
  line-height: 56px;
  text-align: center;
}

.section-hero.center {
  text-align: center;
}

.section-hero.intro-headline {
  max-width: 768px;
  -ms-grid-row-align: center;
  align-self: center;
  font-size: 77px;
  text-align: center;
}

.section-tag-container {
  display: flex;
  margin-top: 40px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.section-tag-container.ar-header {
  display: block;
  padding-top: 0px;
  padding-right: 20px;
  padding-left: 20px;
  border-bottom-style: none;
}

.section-tag-container.ar-header.heading-desc {
  display: flex;
  margin-top: 8px;
  padding-bottom: 0px;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.section-tag-container.ar-header.work {
  padding-bottom: 0px;
}

.section-tag-container.ar-header.center {
  padding-top: 25vh;
  text-align: center;
}

.section-tag-container.ar-header.center.follow {
  padding-top: 20px;
  padding-bottom: 0px;
}

.section-tag-container.ar-third-left {
  display: flex;
  align-items: center;
}

.section-tag-container.center-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-style: none;
}

.section-tag-container.estimate {
  flex-direction: column;
  justify-content: center;
}

.section-tag-container.estimate.hide {
  display: none;
}

.section-tag-container.breaker {
  width: 100vw;
  margin-left: -752px;
  justify-content: center;
  align-items: stretch;
}

.section-tag-container.breaker._w-p {
  max-width: 1400px;
  margin-top: 120px;
  margin-left: -205px;
  flex-direction: row;
}

.section-tag-container.title-center {
  margin-top: 200px;
  padding-top: 0px;
  padding-bottom: 60px;
  font-size: 80px;
  line-height: 1.0714285714;
  text-align: center;
}

.section-tag-container.center {
  justify-content: center;
  text-align: center;
}

.section-tag-container.no-p-t {
  padding-top: 0px;
  padding-bottom: 200px;
}

.section-tag-container.triple {
  padding-top: 200px;
  padding-bottom: 0px;
}

.section-tag-container.intro {
  margin-top: 25vh;
}

.section-tag-container.article {
  position: relative;
  width: 100%;
  max-width: 980px;
  margin-top: 7px;
  justify-content: flex-start;
  font-size: 16px;
  line-height: 16px;
}

.section-img {
  position: relative;
  width: 80%;
  margin-top: 60px;
  flex: 1 0 auto;
}

.section-img.breaker-one {
  max-width: 1600px;
}

.section-img.breaker-three {
  display: flex;
  max-width: 1600px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.section-wrapper {
  display: flex;
  overflow: hidden;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: F37jan, sans-serif;
  color: #fffcfa;
  font-size: 56px;
  line-height: 56px;
  font-weight: 700;
}

.section-wrapper.scroll {
  overflow: scroll;
}

.section-video {
  width: 100%;
  height: 425px;
  margin-top: 88px;
}

.section-content {
  display: flex;
  width: 980px;
  max-width: 100%;
  flex-direction: column;
}

.section-content.top-offset {
  padding-top: 0px;
}

.section-content.bigger {
  width: 100%;
  max-width: 1100px;
  flex-direction: row;
  align-items: center;
}

.section-content.bigger.vert {
  flex-direction: column;
  align-items: flex-start;
}

.section-content.contact {
  min-height: 100%;
  justify-content: center;
}

.section-image-full {
  height: 400px;
  margin-top: 88px;
  padding-top: 56.25%;
  background-image: url("../img/img_cover_52c7c22f-6428-48cd-9116-79a9814f5448.jpg");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.section-image-full.img02 {
  background-image: url("../img/img_02_compressed_resized.png");
  background-position: 0% 50%;
}

.section-image-full.img04 {
  background-image: url("../img/img_04_compressed_resized.png");
  background-position: 50% 50%;
  background-size: contain;
}

.section-image-full.img03 {
  background-image: url("../img/img_03_compressed_resized.png");
  background-position: 100% 50%;
  background-size: contain;
}

.section-image-full._169 {
  background-image: url("../img/img_01_compressed_resized.png");
  background-position: 100% 50%;
}

.section-image-full.breaker-two {
  width: 33%;
  height: auto;
  padding-top: 20%;
  background-image: url("../img/backdrop.png");
}

.section-image-full.breaker-three-portrait {
  width: 33%;
  height: auto;
  padding-top: 55%;
  background-image: url("../img/Screenshot-2023-01-08-at-12.00.07.png");
  background-size: cover;
}

.section-image-full.modular {
  background-image: url("../img/virtual-influencer-modular.png");
  background-size: 800px;
}

.section-image-full.timeline {
  width: 100%;
  height: 25vh;
  padding-top: 0%;
  background-image: url("../img/icon_preview_v01.png");
  background-position: 50% 44%;
  background-size: cover;
}

.section-image-full.round {
  border-radius: 25px;
}

.section-img-grow {
  height: 400px;
  padding-top: 56.25%;
  background-image: url("../img/img_cover_52c7c22f-6428-48cd-9116-79a9814f5448.jpg");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.section-img-grow.img02 {
  background-image: url("../img/img_02_compressed_resized.png");
  background-position: 0% 50%;
}

.section-img-grow.img04 {
  background-image: url("../img/img_04_compressed_resized.png");
  background-position: 50% 50%;
  background-size: contain;
}

.section-img-grow.img03 {
  background-image: url("../img/img_03_compressed_resized.png");
  background-position: 100% 50%;
  background-size: contain;
}

.section-img-grow._169 {
  background-image: url("../img/img_01_compressed_resized.png");
  background-position: 100% 50%;
}

.section-img-grow.breaker-two {
  width: 33%;
  height: auto;
  padding-top: 20%;
  background-image: url("../img/backdrop.png");
}

.section-img-grow.breaker-three-portrait {
  width: 33%;
  height: auto;
  padding-top: 55%;
  background-image: url("../img/Screenshot-2023-01-08-at-12.00.07.png");
  background-size: cover;
}

.section-img-grow.modular {
  background-image: url("../img/virtual-influencer-modular.png");
  background-size: 800px;
}

.section-img-grow.timeline {
  width: 100vw;
  padding-top: 0%;
  background-image: url("../img/icon_preview_v01.png");
  background-position: 50% 44%;
  background-size: cover;
}

.section-img-grow.round {
  border-radius: 25px;
}

.section-full-width {
  width: 100%;
  margin-top: 88px;
}

.section-full-width.img02 {
  background-image: url("../img/img_02_compressed_resized.png");
  background-position: 0% 50%;
}

.section-full-width.img04 {
  background-image: url("../img/img_04_compressed_resized.png");
  background-position: 50% 50%;
  background-size: contain;
}

.section-full-width.img03 {
  background-image: url("../img/img_03_compressed_resized.png");
  background-position: 100% 50%;
  background-size: contain;
}

.section-full-width._169 {
  background-image: url("../img/img_01_compressed_resized.png");
  background-position: 100% 50%;
}

.section-full-width.breaker-two {
  width: 33%;
  height: auto;
  padding-top: 20%;
  background-image: url("../img/backdrop.png");
}

.section-full-width.breaker-three-portrait {
  width: 33%;
  height: auto;
  padding-top: 55%;
  background-image: url("../img/Screenshot-2023-01-08-at-12.00.07.png");
  background-size: cover;
}

.section-full-width.modular {
  background-image: url("../img/virtual-influencer-modular.png");
  background-size: 800px;
}

.section-full-width.timeline {
  width: 100%;
  height: 25vh;
  padding-top: 0%;
  background-image: url("../img/icon_preview_v01.png");
  background-position: 50% 44%;
  background-size: cover;
}

.section-full-width.round {
  border-radius: 25px;
}

.placeholder {
  height: 400px;
  margin-top: 88px;
  padding-top: 56.25%;
  background-image: url("../img/img_cover_52c7c22f-6428-48cd-9116-79a9814f5448.jpg");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.placeholder.img02 {
  background-image: url("../img/img_02_compressed_resized.png");
  background-position: 0% 50%;
}

.placeholder.img04 {
  background-image: url("../img/img_04_compressed_resized.png");
  background-position: 50% 50%;
  background-size: contain;
}

.placeholder.img03 {
  background-image: url("../img/img_03_compressed_resized.png");
  background-position: 100% 50%;
  background-size: contain;
}

.placeholder._169 {
  background-image: url("../img/img_01_compressed_resized.png");
  background-position: 100% 50%;
}

.placeholder.breaker-two {
  width: 33%;
  height: auto;
  padding-top: 20%;
  background-image: url("../img/backdrop.png");
}

.placeholder.breaker-three-portrait {
  width: 33%;
  height: auto;
  padding-top: 55%;
  background-image: url("../img/Screenshot-2023-01-08-at-12.00.07.png");
  background-size: cover;
}

.placeholder.modular {
  background-image: url("../img/virtual-influencer-modular.png");
  background-size: 800px;
}

.placeholder.timeline {
  width: 100%;
  height: 25vh;
  margin-top: 0px;
  padding-top: 0%;
  background-image: url("../img/icon_preview_v01.png");
  background-position: 50% 44%;
  background-size: cover;
}

.placeholder.round {
  border-radius: 25px;
}

.section-vid-overlay-play {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
}

.section-content-item-half {
  width: 50%;
  padding-right: 40px;
  padding-left: 20px;
}

.section-content-item-half.right {
  padding-right: 0px;
  padding-left: 40px;
}

.section-content-item-half.estimate {
  width: 75%;
  padding-right: 20px;
  padding-left: 20px;
}

.section-content-item-half.left {
  padding-right: 40px;
  padding-left: 0px;
}

.section-content-item-half.left.one-third {
  width: 40%;
  padding-left: 0px;
}

.section-content-item-grow {
  position: relative;
  flex: 1 0 auto;
}

.section-content-item-grow.breaker-one {
  max-width: 1600px;
}

.section-content-item-grow.breaker-three {
  display: flex;
  max-width: 1600px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.section-item-img {
  height: 400px;
  padding-top: 56.25%;
  background-image: url("../img/img_cover_52c7c22f-6428-48cd-9116-79a9814f5448.jpg");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.section-item-img.img02 {
  background-image: url("../img/img_02_compressed_resized.png");
  background-position: 0% 50%;
}

.section-item-img.img04 {
  background-image: url("../img/img_04_compressed_resized.png");
  background-position: 50% 50%;
  background-size: contain;
}

.section-item-img.img03 {
  background-image: url("../img/img_03_compressed_resized.png");
  background-position: 100% 50%;
  background-size: contain;
}

.section-item-img._169 {
  background-image: url("../img/img_01_compressed_resized.png");
  background-position: 100% 50%;
}

.section-item-img.breaker-two {
  width: 33%;
  height: auto;
  padding-top: 20%;
  background-image: url("../img/backdrop.png");
}

.section-item-img.breaker-three-portrait {
  width: 33%;
  height: auto;
  padding-top: 55%;
  background-image: url("../img/Screenshot-2023-01-08-at-12.00.07.png");
  background-size: cover;
}

.section-item-img.modular {
  background-image: url("../img/virtual-influencer-modular.png");
  background-size: 800px;
}

.section-item-img.timeline {
  width: 100vw;
  padding-top: 0%;
  background-image: url("../img/icon_preview_v01.png");
  background-position: 50% 44%;
  background-size: cover;
}

.section-item-img.round {
  border-radius: 25px;
}

.section-content-item-half-copy {
  width: 50%;
  padding-right: 40px;
  padding-left: 20px;
}

.section-content-item-half-copy.right {
  padding-right: 20px;
  padding-left: 40px;
}

.section-content-item-half-copy.right.one-third {
  width: 40%;
  padding-right: 0px;
}

.section-content-item-half-copy.estimate {
  width: 75%;
  padding-right: 20px;
  padding-left: 20px;
}

.section-content-item-half-copy.left {
  padding-right: 40px;
  padding-left: 20px;
}

.tag-span {
  margin-top: 0px;
  margin-right: 8px;
  margin-bottom: 7px;
  padding: 5px 13px;
  border-radius: 20px;
  background-color: hsla(0, 0%, 100%, 0.15);
  text-transform: capitalize;
}

.tag-span.no-bg {
  margin-right: 0px;
  padding-right: 9px;
  padding-left: 9px;
  background-color: hsla(0, 0%, 100%, 0);
}

.tag-span.no-bg.absolute {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  transform: translate(0px, 0px);
}

.tag-span.no-bg.absolute.hide {
  display: none;
}

.tag-span.icon {
  padding-left: 29px;
  background-color: rgba(191, 2, 108, 0.25);
  background-image: url("../img/solutions_pink.png");
  background-position: 8px 50%;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  color: #f10388;
}

.tag-span.icon.work {
  background-color: rgba(120, 76, 191, 0.25);
  background-image: url("../img/Remote_purple.png");
  color: #935dea;
}

.tag-span.more {
  text-transform: lowercase;
}

.complication-message {
  display: inline-block;
  padding-top: 25px;
}

.complication-message.img {
  width: 100%;
}

.message-main {
  position: relative;
  display: flex;
  padding: 12px 18px;
  align-items: center;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #fffcfa;
  color: #000c0a;
  font-size: 20px;
  font-weight: 300;
}

.message-main.round {
  position: absolute;
  left: 0%;
  top: auto;
  right: auto;
  bottom: 0%;
  height: 43px;
  border-radius: 30px;
}

.message-main.round.loader-right {
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  background-color: #f10388;
  transform: none;
}

.message-main.right {
  justify-content: flex-end;
  border-top-left-radius: 15px;
  border-top-right-radius: 0px;
  background-color: #f10388;
}

.message-main.img {
  width: 350px;
  height: 196px;
  padding: 0px;
  border-top-left-radius: 15px;
  background-image: url("../img/img_01_compressed_resized.png");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.message-main.hide {
  opacity: 0;
}

.message-txt {
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.message-txt.right {
  color: #fffcfa;
}

.message-emoji {
  height: 100%;
}

.msg-emoji {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 40px;
  height: 100%;
  background-image: url("../img/rock.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  transform: translate(23px, 0px);
}

.msg-emoji.right {
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  transform: translate(-23px, 0px);
}

.msg-emoji.lightning {
  background-image: url("../img/lightning.png");
  background-size: contain;
  transform: translate(20px, 0px);
}

.message-sub {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  padding-top: 10px;
  flex: 1;
  transform: translate(0px, 26px);
  font-family: Inter, sans-serif;
  color: #fffcfa;
  font-size: 16px;
  line-height: 13px;
  font-weight: 400;
  text-align: right;
}

.message-sub.green {
  color: #68ce67;
}

.message-sub.pink {
  color: #f10388;
}

.message-sub.purple {
  color: #935dea;
}

.message-sub.grey {
  color: rgba(255, 252, 250, 0.6);
}

.message-sub.start {
  padding-top: 4px;
}

.message-title {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  padding-bottom: 10px;
  flex: 1;
  transform: translate(0px, -26px);
  font-family: Inter, sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 13px;
  font-weight: 400;
  white-space: nowrap;
}

.message-title.green {
  color: #68ce67;
}

.message-title.pink {
  color: #f10388;
}

.message-title.purple {
  color: #935dea;
}

.message-title.grey {
  color: rgba(255, 252, 250, 0.6);
}

.message-title.start {
  padding-top: 4px;
}

.message-title.right {
  text-align: right;
}

.msg-loader {
  display: flex;
  padding-right: 0px;
  padding-left: 0px;
  justify-content: center;
  align-items: center;
}

.msg-loader-dot {
  width: 12px;
  height: 12px;
  margin: 3px 2px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 50%;
  background-color: #181919;
}

.msg-loader-dot.right {
  background-color: #fffcfa;
}

.btn-parallax-container-alt {
  position: relative;
  display: inline-block;
  margin-right: auto;
  margin-left: auto;
  padding: 24px;
  text-align: left;
}

.btn-parallax-container-alt.nav-header-btn-cnt {
  left: 0px;
  margin-right: -24px;
  margin-bottom: -6px;
  margin-left: -24px;
}

.btn-parallax-alt {
  display: flex;
  height: 42px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 0px;
  padding-right: 20px;
  padding-left: 20px;
  justify-content: center;
  align-items: center;
  border-style: none;
  border-width: 3px;
  border-color: #f10388;
  border-radius: 150px;
  color: #fff;
  font-size: 16px;
  letter-spacing: 1px;
}

.btn-parallax-alt.hover {
  background-color: #f10388;
}

.btn-parallax-alt.nav-header {
  height: 42px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: rgba(241, 3, 136, 0);
}

.nav-header-underline-cnt-alt {
  position: absolute;
  top: 39px;
  width: 100%;
  height: 2px;
  margin-top: 1px;
  background-color: rgba(255, 252, 250, 0.5);
  color: rgba(255, 252, 250, 0.5);
}

.utility-page-wrap {
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  justify-content: center;
  align-items: center;
}

.utility-page-content {
  display: flex;
  width: 260px;
  flex-direction: column;
  text-align: center;
}

.utility-page-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.contact-text-2 {
  position: relative;
  display: inline-block;
  overflow: visible;
  margin-top: 35px;
  padding-right: 0px;
  padding-left: 0px;
  transform: translate(0px, 0px);
  font-family: F37bobby, sans-serif;
  color: #f10388;
  font-size: 77px;
  line-height: 80px;
  font-weight: 300;
}

.contact-text-2.desc {
  width: 100%;
  max-width: 500px;
  margin-top: 11px;
  padding-right: 5px;
  padding-left: 3px;
  font-family: F37jan, sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 25px;
  font-weight: 700;
}

.page-content-2 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-top: 5%;
  padding-right: 20px;
  padding-left: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-content-2.center {
  padding-top: 0%;
  padding-right: 0px;
  padding-left: 0px;
}

.open-for-busi-2 {
  color: #f10388;
  text-transform: uppercase;
}

.getintouch-2 {
  justify-content: center;
  align-items: center;
}

.nav-menu-cnt {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000c0a;
}

.nav-menu-cnt.hide {
  display: none;
}

.nav-menu-content {
  display: flex;
}

.nav-menu-content.bottom {
  position: absolute;
  left: auto;
  top: auto;
  right: auto;
  bottom: 0%;
  padding-bottom: 20px;
}

@media screen and (max-width: 991px) {
  .header-button {
    background-size: 20px 20px;
  }

  .footer.hide {
    display: none;
  }

  .landing-text-el {
    height: 60px;
    font-size: 50px;
    line-height: 50px;
  }

  .landing-text-el.spacer {
    padding-right: 14px;
    padding-left: 5px;
  }

  .index {
    top: -27px;
    font-size: 13px;
  }

  .article-h1 {
    height: 88px;
    line-height: 72px;
  }

  .article-h2 {
    height: 88px;
    line-height: 72px;
  }

  .article-a1 {
    height: 88px;
    line-height: 72px;
  }

  .article-h3 {
    height: 88px;
    line-height: 72px;
  }

  .contact-text {
    font-size: 72px;
    line-height: 72px;
  }

  .awardribbon {
    width: 500px;
  }

  .aw_base.aw_clio {
    margin-right: 8px;
    margin-left: 8px;
  }

  .cookie-background {
    width: 100%;
    max-width: none;
    margin-right: 0px;
    margin-bottom: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .btn-parallax {
    margin-right: 0px;
    margin-left: 0px;
  }

  .cookie_header.spacer {
    padding-right: 14px;
    padding-left: 5px;
  }

  .cookie-cancel-btn-container {
    margin-right: 20px;
  }

  .index-topic {
    top: -27px;
    font-size: 13px;
  }

  .landing-text-el-topic {
    height: 60px;
    font-size: 50px;
    line-height: 50px;
  }

  .landing-text-el-topic.spacer {
    padding-right: 14px;
    padding-left: 5px;
  }

  .sol-fs-btn-exit {
    margin-right: 20px;
  }

  .sol-fs-txt-container {
    height: 60px;
    font-size: 50px;
    line-height: 50px;
  }

  .sol-fs-txt-container.spacer {
    padding-right: 14px;
    padding-left: 5px;
  }

  .sol-fs-index {
    top: -27px;
    font-size: 13px;
  }

  .sol-fs-content {
    width: 100%;
    height: 100%;
  }

  .sol-fs-b {
    margin-bottom: auto;
    -ms-grid-row-align: center;
    align-self: center;
    flex: 1 0 auto;
  }

  .sol-fs-btn-cnt {
    align-items: center;
  }

  .sol-fs-content-wrapper {
    width: 692px;
  }

  .sol-fs-exit-btn-cnt {
    display: none;
  }

  .div-block-26 {
    display: flex;
    width: 100%;
  }

  .div-block-27 {
    flex: 0 0 auto;
  }

  .sol-fs-txt {
    padding-bottom: 6px;
    font-size: 44px;
    line-height: 44px;
  }

  .sol-title-container {
    height: 54px;
  }

  .sol-title-slider-item {
    height: 54px;
    font-size: 44px;
    line-height: 44px;
  }

  .sol-title-slider-item.spacer {
    padding-right: 14px;
    padding-left: 5px;
  }

  .sol-main-el {
    height: 54px;
  }

  .sol-index {
    top: -27px;
    font-size: 13px;
  }

  .text-block-14 {
    font-size: 22px;
    line-height: 22px;
  }

  .article-el.breaker {
    margin-left: -30px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .ar-item-subheader.estimate {
    font-size: 44px;
    line-height: 44px;
  }

  .ar-item-half.right {
    flex: 0 auto;
  }

  .header-nav-btn {
    color: #fff;
  }

  .header-nav-btn.prim {
    color: #fff;
  }

  .nav-header-btn-prim.prim {
    border-style: none;
    background-color: #f10388;
    color: #fffcfa;
  }

  .sol-fs-txt-v2 {
    overflow: hidden;
    padding-bottom: 6px;
    font-size: 44px;
    line-height: 44px;
  }

  .complication-el.align-left {
    margin-left: 50%;
    align-self: flex-start;
  }

  .complication-el.align-right {
    margin-right: 50%;
    align-self: flex-end;
  }

  .complication-el.full-transparent.msg {
    width: 100%;
  }

  .slider-index {
    top: -27px;
  }

  .searchbar-header-cnt {
    max-width: none;
    flex: 1;
  }

  .header-logo-cnt-v3 {
    width: 50px;
    flex: 0 0 auto;
  }

  .header-nav-cnt-v3 {
    margin-left: 20px;
    flex: 0 0 auto;
  }

  .vid-btn-play {
    width: 49px;
    height: 49px;
  }

  .vid-btn-play.pause {
    background-size: 36px;
  }

  .vid-btn-play.play {
    background-size: 36px;
  }

  .section-title-sub {
    font-size: 34px;
  }

  .section-title-sub.estimate {
    font-size: 44px;
    line-height: 44px;
  }

  .section-title-sub.router {
    width: 100%;
    max-width: none;
    padding-right: 20px;
    padding-left: 20px;
  }

  .section-description {
    font-size: 18px;
  }

  .section-description.center.contact {
    max-width: 500px;
  }

  .router-item {
    padding-top: 32px;
  }

  .router-item.left {
    padding-right: 0px;
    padding-left: 0px;
  }

  .router-img {
    height: 250px;
    background-size: contain;
  }

  .section-div {
    padding-top: 150px;
    padding-right: 40px;
    padding-left: 40px;
  }

  .section-div.breaker {
    margin-left: -30px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .section-div.full-width {
    padding-right: 0px;
    padding-left: 0px;
  }

  .section-div.grow-universe {
    padding-top: 80px;
  }

  .section-hero.estimate {
    font-size: 44px;
    line-height: 44px;
  }

  .section-hero.intro-headline {
    max-width: 500px;
    font-size: 50px;
  }

  .section-tag-container.breaker {
    margin-left: -30px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .section-wrapper {
    overflow: hidden;
    padding-bottom: 6px;
    font-size: 44px;
    line-height: 44px;
  }

  .section-video {
    margin-top: 34px;
  }

  .section-content.top-offset {
    padding-top: 34px;
  }

  .section-content.bigger {
    width: 100%;
    max-width: 980px;
  }

  .section-image-full {
    margin-top: 34px;
  }

  .section-full-width {
    margin-top: 34px;
  }

  .placeholder {
    margin-top: 34px;
  }

  .section-content-item-half.right {
    flex: 0 auto;
  }

  .section-content-item-half-copy.right {
    flex: 0 auto;
  }

  .btn-parallax-alt {
    margin-right: 0px;
    margin-left: 0px;
  }

  .contact-text-2 {
    font-size: 72px;
    line-height: 72px;
  }
}

@media screen and (max-width: 767px) {
  .footer {
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    display: none;
    height: 30px;
    padding-top: 7px;
    padding-right: 40px;
    font-size: 10px;
    line-height: 10px;
    text-align: center;
  }

  .landing-text-el {
    height: 48px;
    padding-top: 12px;
    padding-right: 9px;
    padding-left: 9px;
    font-size: 40px;
    line-height: 40px;
  }

  .landing-text-el.nohover {
    padding-top: 12px;
  }

  .landing-text-el.nohover.left {
    padding-left: 21px;
  }

  .main-el {
    height: 40px;
  }

  .main-el.higher {
    height: 55px;
  }

  .index {
    top: -19px;
    font-size: 11px;
    line-height: 21px;
  }

  .landing-btn {
    height: 50px;
    margin-right: 0px;
    margin-left: 0px;
    padding-top: 12px;
    padding-right: 29px;
    padding-left: 21px;
  }

  .lading-btn-container {
    margin-top: 24px;
    padding-bottom: 0px;
  }

  .preloader {
    width: 60px;
    height: 60px;
    margin-top: -30px;
    margin-left: -30px;
    border-radius: 18px;
  }

  .article-h1 {
    height: 48px;
    padding-top: 12px;
    padding-right: 9px;
    padding-left: 9px;
  }

  .article-h1.nohover {
    padding-top: 12px;
  }

  .article-h1.nohover.left {
    padding-left: 21px;
  }

  .article-h2 {
    height: 48px;
    padding-top: 12px;
    padding-right: 9px;
    padding-left: 9px;
  }

  .article-h2.nohover {
    padding-top: 12px;
  }

  .article-h2.nohover.left {
    padding-left: 21px;
  }

  .article-a1 {
    height: 48px;
    padding-top: 12px;
    padding-right: 9px;
    padding-left: 9px;
  }

  .article-a1.nohover {
    padding-top: 12px;
  }

  .article-a1.nohover.left {
    padding-left: 21px;
  }

  .article-h3 {
    height: 48px;
    padding-top: 12px;
    padding-right: 9px;
    padding-left: 9px;
    font-size: 40px;
    line-height: 40px;
  }

  .article-h3.nohover {
    padding-top: 12px;
  }

  .article-h3.nohover.left {
    padding-left: 21px;
  }

  .contact-box {
    width: 100vw;
    height: 100vw;
    max-height: 280px;
    max-width: 280px;
  }

  .contact-box-text {
    font-size: 14px;
  }

  .contact-text {
    padding-top: 12px;
    padding-right: 9px;
    padding-left: 9px;
    font-size: 40px;
    line-height: 40px;
  }

  .contact-text.nohover {
    padding-top: 12px;
  }

  .contact-text.nohover.left {
    padding-left: 21px;
  }

  .contact-text.desc {
    margin-top: -5px;
    font-size: 14px;
  }

  .awardribbon {
    width: 450px;
  }

  .award-container {
    position: absolute;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 50px;
    display: none;
  }

  .cookie-container {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }

  .header-back-btn {
    font-size: 14px;
  }

  .cookie-background {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .btn-parallax {
    margin-right: 0px;
    margin-left: 0px;
  }

  .cookie_header.nohover {
    padding-top: 12px;
  }

  .cookie_header.nohover.left {
    padding-left: 21px;
  }

  .btn-parallax-container.nav {
    margin-left: 0px;
  }

  .main-el-topic {
    height: 40px;
  }

  .main-el-topic.higher {
    height: 55px;
  }

  .index-topic {
    top: -19px;
    font-size: 11px;
    line-height: 21px;
  }

  .landing-text-el-topic {
    height: 48px;
    padding-top: 12px;
    padding-right: 9px;
    padding-left: 9px;
    font-size: 40px;
    line-height: 40px;
  }

  .landing-text-el-topic.nohover {
    padding-top: 12px;
  }

  .landing-text-el-topic.nohover.left {
    padding-left: 21px;
  }

  .sol-fs-txt-container {
    height: 48px;
    padding-top: 12px;
    padding-right: 9px;
    padding-left: 9px;
    font-size: 40px;
    line-height: 40px;
  }

  .sol-fs-txt-container.nohover {
    padding-top: 12px;
  }

  .sol-fs-txt-container.nohover.left {
    padding-left: 21px;
  }

  .sol-fs-index {
    top: -19px;
    font-size: 11px;
    line-height: 21px;
  }

  .sol-fs-txt-main {
    height: 40px;
  }

  .sol-fs-txt-main.higher {
    height: 55px;
  }

  .sol-fs-content-wrapper {
    width: 100%;
  }

  .sol-title-slider-item.nohover {
    padding-top: 12px;
  }

  .sol-title-slider-item.nohover.left {
    padding-left: 21px;
  }

  .sol-main-el {
    height: 40px;
  }

  .sol-main-el.higher {
    height: 55px;
  }

  .sol-index {
    top: -19px;
    font-size: 11px;
    line-height: 21px;
  }

  .article-el.ar-header {
    padding-right: 20px;
    padding-left: 20px;
  }

  .article-el.breaker {
    margin-left: 0px;
  }

  .ar-item-onethird {
    padding-right: 40px;
    padding-left: 25px;
  }

  .header-nav-btn {
    font-size: 14px;
  }

  .header-center-btn-cnt {
    flex: 0 0 auto;
    font-size: 14px;
  }

  .fs-txt-content {
    width: 100%;
  }

  .slider-desc-main {
    font-size: 27px;
    line-height: 27px;
  }

  .slider-desc-main.higher {
    height: 55px;
  }

  .slider-index {
    top: -19px;
    font-size: 15px;
    line-height: 15px;
  }

  .slider-desc-main-under {
    font-size: 27px;
    line-height: 27px;
  }

  .slider-desc-main-under.higher {
    height: 55px;
  }

  .rotate-screen-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #000;
  }

  .rotate-phone-text {
    color: #fffcfa;
  }

  .rotate-phone-icon {
    width: 64px;
    height: 64px;
    margin-bottom: 20px;
    background-image: url("../img/rotate_your_phone.png");
    background-position: 0px 0px;
    background-size: contain;
    background-clip: border-box;
    -webkit-text-fill-color: inherit;
  }

  .searchbar-header-cnt {
    flex: 1;
  }

  .header-logo-cnt-v3 {
    width: 50px;
  }

  .vid-btn-play {
    width: 39px;
    height: 39px;
  }

  .vid-btn-play.pause {
    background-size: 29px;
  }

  .vid-btn-play.play {
    background-size: 29px;
  }

  .section-title-sub.bottom-padding {
    padding-bottom: 35px;
  }

  .section-description {
    margin-top: 35px;
  }

  .section-div {
    padding-top: 100px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .section-div.ar-header {
    padding-right: 20px;
    padding-left: 20px;
  }

  .section-div.breaker {
    margin-left: 0px;
  }

  .section-div.full-width {
    padding-top: 35px;
  }

  .section-hero.intro-headline {
    font-size: 40px;
  }

  .section-tag-container {
    margin-top: 35px;
  }

  .section-tag-container.ar-header {
    padding-right: 20px;
    padding-left: 20px;
  }

  .section-tag-container.breaker {
    margin-left: 0px;
  }

  .section-img {
    width: 100%;
    margin-top: 35px;
  }

  .section-content-item-half.right {
    padding-left: 20px;
  }

  .section-content-item-half.left {
    padding-right: 20px;
  }

  .section-content-item-half.left.one-third {
    padding-right: 20px;
  }

  .section-content-item-half-copy.right.one-third {
    padding-left: 20px;
  }

  .message-main {
    padding: 10px 15px;
  }

  .message-main.round {
    height: 40px;
  }

  .message-txt {
    font-size: 16px;
    line-height: 20px;
  }

  .message-sub {
    transform: translate(0px, 21px);
    font-size: 13px;
  }

  .message-title {
    transform: translate(0px, -21px);
    font-size: 13px;
  }

  .msg-loader-dot {
    width: 11px;
    height: 11px;
    margin-right: 2px;
    margin-left: 2px;
  }

  .btn-parallax-alt {
    margin-right: 0px;
    margin-left: 0px;
  }

  .contact-text-2 {
    padding-top: 12px;
    padding-right: 9px;
    padding-left: 9px;
    font-size: 40px;
    line-height: 40px;
  }

  .contact-text-2.desc {
    margin-top: -5px;
    font-size: 14px;
  }

  .nav-menu-content {
    flex-direction: row;
  }
}

@media screen and (max-width: 479px) {
  .page-content {
    justify-content: center;
    align-items: stretch;
  }

  .page-content.center {
    padding-top: 40px;
    padding-right: 0px;
    justify-content: center;
    align-items: center;
  }

  .header {
    display: none;
    padding-left: 30px;
  }

  .header.hidden {
    display: none;
  }

  .header-logo.hide {
    display: none;
  }

  .header-button {
    display: none;
  }

  .footer {
    display: none;
  }

  .page-content-container {
    flex-wrap: wrap;
  }

  .page-content-container.ribbon {
    position: absolute;
  }

  .page-content-container.landing-awards {
    position: absolute;
    width: 100%;
  }

  .landing-text-el {
    height: 40px;
    padding-top: 6px;
    padding-right: 0px;
    padding-left: 0px;
    flex: 0 0 auto;
    font-size: 30px;
    line-height: 30px;
  }

  .landing-text-el.spacer {
    padding-right: 3px;
    padding-left: 3px;
  }

  .landing-text-el.nohover {
    padding-top: 6px;
    padding-right: 2px;
    padding-left: 2px;
  }

  .landing-text-el.nohover.left {
    padding-left: 0px;
  }

  .main-el {
    height: 30px;
  }

  .main-el.nohover {
    padding-right: 5px;
  }

  .index {
    font-size: 10px;
  }

  .landing-btn {
    height: 42px;
    padding-top: 8px;
    font-size: 14px;
  }

  .lading-btn-container {
    display: flex;
    width: 100%;
    padding-top: 20px;
    justify-content: center;
  }

  .landing-btn-arrow {
    display: none;
  }

  .side-contact {
    display: none;
  }

  .grid {
    display: none;
  }

  .contact-box {
    width: 250px;
    height: 250px;
    max-width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .contact-box-text {
    font-size: 14px;
  }

  .contact-text {
    font-size: 40px;
    line-height: 40px;
  }

  .contact-text.desc {
    padding-right: 20px;
    padding-left: 20px;
    font-size: 14px;
  }

  .awardribbon {
    width: 100%;
  }

  .award-container {
    bottom: 30px;
    display: flex;
    margin-bottom: 20px;
    padding-right: 11px;
    padding-left: 14px;
    justify-content: center;
    text-align: center;
  }

  .aw_base {
    padding-top: 5px;
    padding-bottom: 5px;
    flex: 1;
  }

  .cookie-header {
    font-size: 35px;
  }

  .header-burger-btn-insta {
    display: block;
    flex: 0 auto;
    background-image: url("../img/mail.png");
    background-size: contain;
  }

  .header-icon-menu {
    flex: 1;
  }

  .btn-parallax.nav-header {
    height: 30px;
    padding-right: 5px;
    padding-left: 5px;
  }

  .cookie_header {
    height: 40px;
    padding-top: 6px;
    padding-right: 0px;
    padding-left: 0px;
    flex: 0 0 auto;
    font-size: 30px;
    line-height: 30px;
  }

  .cookie_header.spacer {
    padding-right: 3px;
    padding-left: 3px;
  }

  .cookie_header.nohover {
    padding-top: 6px;
    padding-right: 2px;
    padding-left: 2px;
  }

  .cookie_header.nohover.left {
    padding-left: 0px;
  }

  .cookie-header-container {
    margin-bottom: 28px;
  }

  .cookie-header-caption {
    margin-bottom: 14px;
  }

  .cookie-consent-text {
    margin-bottom: 42px;
  }

  .btn-parallax-text {
    font-size: 14px;
  }

  .btn-parallax-container {
    padding-right: 0px;
    padding-left: 0px;
  }

  .btn-parallax-container.small-p {
    padding: 0px;
  }

  .btn-parallax-container.nav {
    margin-right: 0px;
    margin-left: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .solution-card {
    padding-top: 62.5%;
  }

  .grid-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .main-el-topic {
    height: 30px;
  }

  .main-el-topic.nohover {
    padding-right: 5px;
  }

  .index-topic {
    font-size: 10px;
  }

  .landing-text-el-topic {
    height: 40px;
    padding-top: 6px;
    padding-right: 0px;
    padding-left: 0px;
    flex: 0 0 auto;
    font-size: 30px;
    line-height: 30px;
  }

  .landing-text-el-topic.spacer {
    padding-right: 3px;
    padding-left: 3px;
  }

  .landing-text-el-topic.nohover {
    padding-top: 6px;
    padding-right: 2px;
    padding-left: 2px;
  }

  .landing-text-el-topic.nohover.left {
    padding-left: 0px;
  }

  .solution-container-full {
    flex-direction: column;
  }

  .player-caption {
    margin-bottom: 14px;
  }

  .solution-card-full {
    overflow: visible;
    width: 100%;
    height: auto;
    padding-top: 63%;
    padding-right: 0px;
    padding-left: 0%;
  }

  .player-desc-caption {
    margin-bottom: 14px;
  }

  .sol-fs-btn-exit {
    display: none;
  }

  .autoplay {
    margin-bottom: 14px;
  }

  .sol-fs-txt-container {
    height: 40px;
    padding-top: 6px;
    padding-right: 0px;
    padding-left: 0px;
    flex: 0 0 auto;
    font-size: 30px;
    line-height: 30px;
  }

  .sol-fs-txt-container.spacer {
    padding-right: 3px;
    padding-left: 3px;
  }

  .sol-fs-txt-container.nohover {
    padding-top: 6px;
    padding-right: 2px;
    padding-left: 2px;
  }

  .sol-fs-txt-container.nohover.left {
    padding-left: 0px;
  }

  .sol-fs-index {
    font-size: 10px;
  }

  .sol-fs-txt-main {
    height: 30px;
  }

  .sol-fs-txt-main.nohover {
    padding-right: 5px;
  }

  .sol-fs-btn-cnt {
    height: 85px;
  }

  .sol-fs-content-wrapper {
    padding-right: 0px;
    padding-left: 0px;
  }

  .sol-fs-txt {
    padding-bottom: 4px;
    font-size: 27px;
    line-height: 30px;
  }

  .sol-fs-progressbar {
    width: 100%;
    min-width: auto;
  }

  .sol-title-container {
    height: 40px;
  }

  .sol-title-slider-item {
    height: 40px;
    padding-top: 6px;
    padding-right: 0px;
    padding-left: 0px;
    flex: 0 0 auto;
    font-size: 30px;
    line-height: 30px;
  }

  .sol-title-slider-item.spacer {
    padding-right: 3px;
    padding-left: 3px;
  }

  .sol-title-slider-item.nohover {
    padding-top: 6px;
    padding-right: 2px;
    padding-left: 2px;
  }

  .sol-title-slider-item.nohover.left {
    padding-left: 0px;
  }

  .sol-main-el {
    height: 30px;
    font-size: 27px;
    line-height: 27px;
  }

  .sol-main-el.nohover {
    padding-right: 5px;
  }

  .sol-index {
    font-size: 15px;
    line-height: 15px;
  }

  .sol-fs-btn-timeline-spacer.hidden.compress {
    display: block;
    margin-right: 5px;
    margin-left: 5px;
    flex: 0 auto;
  }

  .sol-fs-btn-parallax-cnt.arrow {
    flex: 0 auto;
  }

  .div-block-32 {
    flex-direction: column;
  }

  .text-block-14 {
    font-size: 16px;
  }

  .sol-fs-txt-img {
    width: 100%;
  }

  .div-block-34 {
    width: 100%;
    background-image: url("../img/group_40.png");
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .div-block-35 {
    width: 100%;
  }

  .text-block-16 {
    transform: rotate(90deg);
  }

  .article-el {
    margin-bottom: 0px;
    padding-top: 40px;
    padding-bottom: 40px;
    flex-direction: column-reverse;
    border-bottom-style: solid;
  }

  .article-el.ar-header {
    padding-right: 25px;
    padding-left: 25px;
  }

  .article-el.ar-header.heading-desc {
    margin-top: 0px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .article-el.ar-third-left {
    overflow: hidden;
    margin-bottom: 0px;
    padding-top: 0px;
    flex-direction: column-reverse;
    border-top: 1px none rgba(255, 252, 250, 0.25);
    border-bottom: 1px solid rgba(255, 252, 250, 0.25);
  }

  .article-el.center-tag {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 2px;
  }

  .article-el.estimate {
    border-bottom-style: none;
  }

  .article-el.breaker._w-p {
    flex-direction: column;
  }

  .ar-item-onethird {
    width: 100%;
    padding: 25px;
  }

  .ar-item-onethird.right {
    padding-right: 25px;
    padding-left: 25px;
  }

  .ar-item-grow {
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .ar-item-subheader {
    margin-bottom: 20px;
    font-size: 27px;
    line-height: 30px;
    text-align: left;
  }

  .ar-item-subheader.estimate {
    font-size: 27px;
    line-height: 30px;
  }

  .ar-item-desc {
    color: rgba(255, 252, 250, 0.5);
    font-size: 15px;
    line-height: 19px;
  }

  .ar-item-img {
    width: 100%;
    height: auto;
    transform: translate(0px, 0px);
  }

  .ar-item-img.img02 {
    background-image: url("../img/img_02_mobile_resized.png");
    background-position: 50% 50%;
    background-size: contain;
  }

  .ar-item-img.img03 {
    background-position: 50% 50%;
  }

  .ar-item-img._169 {
    background-position: 50% 50%;
    background-size: contain;
  }

  .ar-item-half {
    width: 100%;
    padding: 25px;
  }

  .ar-item-half.right {
    padding: 35px 0px;
  }

  .ar-item-half.estimate {
    width: 100%;
    padding-right: 25px;
    padding-left: 25px;
  }

  .text-span-8 {
    color: #fffcfa;
  }

  .text-span-9 {
    color: #f10388;
  }

  .text-span-10 {
    color: #f10388;
  }

  .text-span-11 {
    color: #fffcfa;
  }

  .text-pink {
    color: #f10388;
  }

  .header_v2 {
    height: 80px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .header-center-btn-cnt {
    text-align: center;
  }

  .sol-fs-txt-v2 {
    padding-bottom: 4px;
    font-size: 27px;
    line-height: 30px;
  }

  .sol-fs-btn-parallax-cnt-arrow.arrow {
    flex: 0 auto;
  }

  .complication-el {
    max-width: 80%;
  }

  .compilation-row.timeline {
    transform: scale3d(1none, 1none, 1none);
  }

  .compilation-row.msg {
    transform: scale3d(1none, 1none, 1none);
  }

  .complications-status-cnt {
    width: 100%;
  }

  .status-slider {
    width: 150px;
  }

  .compilation-slider-cnt {
    width: 100%;
  }

  .complication-slider-txt-cnt {
    flex: 0 0px;
  }

  .complication-slider-txt-cnt.slider {
    flex: 0 0px;
  }

  .complication-slider-heading {
    white-space: nowrap;
  }

  .comp-shot-track {
    display: flex;
    justify-content: center;
  }

  .comp-music-track {
    display: flex;
    justify-content: center;
  }

  .back-txt.transparent.alt {
    font-size: 14px;
  }

  .text-white-header-index {
    font-size: 15px;
    line-height: 15px;
  }

  .slider-desc-main {
    font-size: 27px;
    line-height: 27px;
  }

  .slider-desc-main.nohover {
    padding-right: 5px;
  }

  .slider-tag-new {
    margin-right: 6px;
    padding: 3px 6px;
  }

  .slider-tag-new.solution.header-back {
    width: 355px;
    height: 40px;
    margin-top: 17px;
    padding-top: 0px;
    padding-bottom: 0px;
    justify-content: flex-start;
    border-style: none;
    border-width: 1px;
    border-color: rgba(255, 252, 250, 0.5);
    border-radius: 12px;
    background-color: rgba(255, 252, 250, 0.15);
  }

  .slider-tag-new.no-bg {
    margin-right: 4px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .slider-tag-new.solution-slide.header-back {
    width: 355px;
    height: 40px;
    margin-top: 17px;
    padding-top: 0px;
    padding-bottom: 0px;
    justify-content: flex-start;
    border-style: none;
    border-width: 1px;
    border-color: rgba(255, 252, 250, 0.5);
    border-radius: 12px;
    background-color: rgba(255, 252, 250, 0.15);
  }

  .div-block-45.chevron.google {
    margin-left: 3px;
    padding-left: 21px;
    background-image: url("../img/lupe.png");
  }

  .slider-desc-main-under {
    font-size: 27px;
    line-height: 27px;
  }

  .slider-desc-main-under.nohover {
    padding-right: 5px;
  }

  .slider-tag-new-txt {
    padding: 2px 4px;
    border-style: none;
    border-width: 1px;
    border-color: #fffcfa;
    border-radius: 20px;
    font-size: 13px;
    line-height: 13px;
  }

  .rotate-screen-wrapper {
    display: none;
  }

  .searchbar-header-cnt {
    margin-right: 10px;
    margin-left: 10px;
    flex: 1;
  }

  .header_v3 {
    height: 80px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .header-logo-cnt-v3 {
    width: 40px;
    flex: 0 0 auto;
  }

  .header-burger-btn-insta-v3 {
    position: relative;
    z-index: 10000;
    display: block;
    width: 35px;
    margin-right: -8px;
    margin-left: -10px;
    flex: 0 0 auto;
    background-image: url("../img/mail.png");
    background-size: contain;
  }

  .searchbar-tag-bar {
    padding-right: 10px;
    padding-left: 10px;
    font-size: 14px;
  }

  .searchbar-drop-down {
    overflow: hidden;
  }

  .searchbar-filter-row {
    width: 100%;
  }

  .searchbar-filter-item {
    padding: 5px 10px;
    font-size: 14px;
  }

  .ar-item-subheader-spacer {
    height: 20px;
    margin-bottom: 20px;
    font-size: 40px;
    line-height: 44px;
    text-align: left;
  }

  .tag-slide-icon.chevron.google {
    margin-left: 3px;
    padding-left: 21px;
    background-image: url("../img/lupe.png");
  }

  .ar-item-full {
    width: 100%;
    padding: 25px;
  }

  .ar-item-full.right {
    padding-right: 25px;
    padding-left: 25px;
  }

  .ar-item-full.estimate {
    width: 100%;
    padding-right: 25px;
    padding-left: 25px;
  }

  .section-title-sub {
    font-size: 27px;
    line-height: 30px;
  }

  .section-title-sub.estimate {
    font-size: 27px;
    line-height: 30px;
  }

  .section-description {
    color: rgba(255, 252, 250, 0.5);
    font-size: 15px;
    line-height: 19px;
  }

  .section-description.center.contact {
    max-width: 400px;
  }

  .router-item {
    width: 100%;
  }

  .router-item.left {
    margin-right: 0px;
  }

  .router-item.right {
    margin-top: 20px;
    margin-left: 0px;
  }

  .router-row {
    flex-direction: column;
  }

  .section-div.ar-header {
    padding-right: 25px;
    padding-left: 25px;
  }

  .section-div.ar-header.heading-desc {
    margin-top: 0px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .section-div.ar-third-left {
    overflow: hidden;
    margin-bottom: 0px;
    padding-top: 0px;
    flex-direction: column-reverse;
    border-top: 1px none rgba(255, 252, 250, 0.25);
    border-bottom: 1px solid rgba(255, 252, 250, 0.25);
  }

  .section-div.center-tag {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 2px;
  }

  .section-div.estimate {
    border-bottom-style: none;
  }

  .section-div.breaker._w-p {
    flex-direction: column;
  }

  .section-div.grow-universe {
    padding-top: 54px;
  }

  .section-hero {
    font-size: 27px;
    line-height: 30px;
    text-align: left;
  }

  .section-hero.estimate {
    font-size: 27px;
    line-height: 30px;
  }

  .section-hero.intro-headline {
    font-size: 30px;
  }

  .section-tag-container.ar-header {
    padding-right: 25px;
    padding-left: 25px;
  }

  .section-tag-container.ar-header.heading-desc {
    margin-top: 0px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .section-tag-container.ar-third-left {
    overflow: hidden;
    margin-bottom: 0px;
    padding-top: 0px;
    flex-direction: column-reverse;
    border-top: 1px none rgba(255, 252, 250, 0.25);
    border-bottom: 1px solid rgba(255, 252, 250, 0.25);
  }

  .section-tag-container.center-tag {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 2px;
  }

  .section-tag-container.estimate {
    border-bottom-style: none;
  }

  .section-tag-container.breaker._w-p {
    flex-direction: column;
  }

  .section-tag-container.article {
    margin-top: 7px;
  }

  .section-img {
    width: 100%;
  }

  .section-wrapper {
    padding-bottom: 4px;
    font-size: 27px;
    line-height: 30px;
  }

  .section-content.bigger {
    width: 100%;
    flex-direction: column-reverse;
  }

  .section-content.bigger.left {
    flex-direction: column;
  }

  .section-content.contact {
    padding-right: 20px;
    padding-bottom: 0px;
    padding-left: 20px;
  }

  .section-image-full {
    width: 100vw;
    height: auto;
    transform: translate(0px, 0px);
  }

  .section-image-full.img02 {
    background-image: url("../img/img_02_mobile_resized.png");
    background-position: 50% 50%;
    background-size: contain;
  }

  .section-image-full.img03 {
    background-position: 50% 50%;
  }

  .section-image-full._169 {
    background-position: 50% 50%;
    background-size: contain;
  }

  .section-img-grow {
    width: 100vw;
    height: auto;
    transform: translate(0px, 0px);
  }

  .section-img-grow.img02 {
    background-image: url("../img/img_02_mobile_resized.png");
    background-position: 50% 50%;
    background-size: contain;
  }

  .section-img-grow.img03 {
    background-position: 50% 50%;
  }

  .section-img-grow._169 {
    background-position: 50% 50%;
    background-size: contain;
  }

  .section-img-grow.round {
    width: 100%;
  }

  .section-full-width {
    width: 100vw;
    height: auto;
    transform: translate(0px, 0px);
  }

  .section-full-width.img02 {
    background-image: url("../img/img_02_mobile_resized.png");
    background-position: 50% 50%;
    background-size: contain;
  }

  .section-full-width.img03 {
    background-position: 50% 50%;
  }

  .section-full-width._169 {
    background-position: 50% 50%;
    background-size: contain;
  }

  .placeholder {
    width: 100vw;
    height: auto;
    transform: translate(0px, 0px);
  }

  .placeholder.img02 {
    background-image: url("../img/img_02_mobile_resized.png");
    background-position: 50% 50%;
    background-size: contain;
  }

  .placeholder.img03 {
    background-position: 50% 50%;
  }

  .placeholder._169 {
    background-position: 50% 50%;
    background-size: contain;
  }

  .section-content-item-half {
    width: 100%;
    padding: 25px;
  }

  .section-content-item-half.right {
    padding: 35px 20px;
  }

  .section-content-item-half.estimate {
    width: 100%;
    padding-right: 25px;
    padding-left: 25px;
  }

  .section-content-item-half.left {
    padding-left: 20px;
  }

  .section-content-item-half.left.one-third {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }

  .section-content-item-grow {
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .section-item-img {
    width: 100%;
    height: auto;
    transform: translate(0px, 0px);
  }

  .section-item-img.img02 {
    background-image: url("../img/img_02_mobile_resized.png");
    background-position: 50% 50%;
    background-size: contain;
  }

  .section-item-img.img03 {
    background-position: 50% 50%;
  }

  .section-item-img._169 {
    background-position: 50% 50%;
    background-size: contain;
  }

  .section-content-item-half-copy {
    width: 100%;
    padding: 25px;
  }

  .section-content-item-half-copy.right {
    padding: 35px 0px;
  }

  .section-content-item-half-copy.right.one-third {
    width: 100%;
    padding-right: 20px;
  }

  .section-content-item-half-copy.estimate {
    width: 100%;
    padding-right: 25px;
    padding-left: 25px;
  }

  .tag-span {
    margin-right: 6px;
    padding: 3px 11px;
    font-size: 13px;
  }

  .tag-span.no-bg {
    padding-right: 7px;
    padding-left: 7px;
  }

  .tag-span.icon {
    margin-right: 4px;
    padding-right: 9px;
    padding-left: 27px;
  }

  .message-main.round.loader-right {
    left: auto;
    top: 0%;
    right: 0%;
    bottom: auto;
    transform: translate(0px, 25px);
  }

  .message-main.round.loader-right.hide {
    display: none;
  }

  .message-main.right {
    display: inline-block;
    text-align: left;
  }

  .message-main.img {
    max-width: 100%;
  }

  .message-txt {
    line-height: 20px;
  }

  .message-txt.right {
    display: inline;
  }

  .btn-parallax-alt.nav-header {
    height: 30px;
    padding-right: 5px;
    padding-left: 5px;
  }

  .contact-text-2 {
    font-size: 40px;
    line-height: 40px;
  }

  .contact-text-2.desc {
    padding-right: 20px;
    padding-left: 20px;
    font-size: 14px;
  }

  .page-content-2 {
    justify-content: center;
    align-items: stretch;
  }

  .page-content-2.center {
    padding-top: 40px;
    padding-right: 0px;
    justify-content: center;
    align-items: center;
  }

  .nav-menu-content {
    flex-direction: column;
    align-items: center;
  }
}
