.burger-con {
  cursor: pointer;
  display: inline-block;
}

.burger-bar {
  display: block;
  height: 2px;
  width: 25px;
  background: white;
  margin: 5px auto;
  border-radius: 4px;
}

.burger-con {
  width: auto;
  margin: 0 auto;
}

.burger-con .middle {
  margin: 0 auto;
}

.burger-con .bottom {
  width: 20px;
  transform: translateX(2px);
}

.header-burger-btn-insta-v3 {
  background-image: none;
}
